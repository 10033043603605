import React, { createElement, useState } from 'react';
import {
  List,
  Pagination,
  Edit,
  Create,
  DatagridConfigurable,
  TextField,
  ReferenceField,
  FunctionField,
  TextInput,
  NumberInput,
  CheckboxGroupInput,
  NullableBooleanInput,
  PasswordInput,
  RadioButtonGroupInput,
  UrlField,
  SimpleForm,
  TopToolbar,
  Datagrid,
  CreateButton,
  SelectInput,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  DateInput,
  TabbedForm,
  SelectArrayInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ArrayInput,
  SimpleFormIterator,
  useRecordContext,
  SelectColumnsButton,
  FilterButton,
  ExportButton,
  SavedQueriesList,
  FilterLiveSearch,
  FilterList,
  FilterListItem,
  CloneButton,
  UpdateButton,
  DeleteWithConfirmButton,
  DeleteButton,
  Button,
  DateField,
  FileInput,
  FileField,
  ImageInput,
  ImageField,
  useDataProvider,
  useGetList,
  BooleanField,
  Loading,
  required,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  useUpdate,
  Toolbar,
  SaveButton,
  useNotify,
  useRefresh,
  useGetRecordId,
  WithListContext,
  SimpleShowLayout,
  BulkDeleteButton,
  BulkExportButton,
  BulkUpdateButton,
  TimeInput,
  ArrayField,
  Labeled,
  useListContext,
  SelectField,
  useListParams,
  RichTextField,
  useRedirect,
  useTheme,
} from 'react-admin';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { useWatch, useFormContext } from "react-hook-form"
import { IconButton, Avatar, Typography, Tooltip, Chip, Grid, Box, Stack, Card, CardMedia, CardContent, Button as MButton, Accordion, AccordionActions, AccordionSummary, AccordionDetails } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import * as MuiIcons from "@material-ui/icons";
import { ReferenceManyInput, DualListInput } from '@react-admin/ra-relationships';
import { Calendar } from '@react-admin/ra-calendar';
import { WizardForm, LongForm, AccordionForm, CreateInDialogButton, EditInDialogButton, BulkUpdateFormButton } from '@react-admin/ra-form-layout';
import { SmartRichTextInput } from '@react-admin/ra-ai';
import KitAppMinMaxDateOrTimeInput from '../components/ui/KitAppMinMaxDateOrTimeInput';
import KitAppNumber from '../components/ui/KitAppNumber';
import CreateReference from '../components/ui/CreateReference';
import LocalListController from '../components/ui/UseLocalListController'
import { NivoSimpleGraph } from './graphs/NivoSimpleGraph';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';


const Ajv = require("ajv")
const ajv = new Ajv()
let _ = require('lodash');

const filterToQuery = (searchText, v) => {
  if (v.filterToQuery == "id") {
    return { id: `${searchText}` }
  }
  return { q: `${searchText}` }
}


let isString = value => typeof value === 'string' || value instanceof String;


const optionRenderer = (record, v, optionListParamName = "optionList") => {
  if (_.get(v, optionListParamName) === undefined) {
    return (_.has(record, 'name')) ? (record.name) : (record.id)
  } else {
    let s = ""
    _.get(v, optionListParamName).forEach(e => {
      if (isString(e)) {
        s += ((_.has(record, e)) ? (_.get(record, e)) : (e))
      } else {
        let curval = _.get(record, e.field)
        if (_.has(e, "transformation")) {
          if (e.transformation.type === "division") {
            if (e.transformation.toLocaleString) {
              curval = (curval / e.transformation.value).toLocaleString(e.transformation.toLocaleString)
            } else {
              curval = curval / e.transformation.value
            }
          }
        }
        s += curval
      }
    });
    return s
  }
}


const EditTitleRenderer = (props) => {
  const { v } = props;
  const record = useRecordContext();
  if (record === undefined) {
    document.title = "Загрузка..."
    return "Загрузка..."
  } else if (v.titleList === undefined) {
    document.title = "Редактирование #" + record.id
    return "Редактирование #" + record.id
  } else {
    let s = ""
    v.titleList.forEach(e => {
      s += ((_.has(record, e)) ? (_.get(record, e)) : (e))
    });
    if (v.tabTitleList === undefined) {
      document.title = s
    } else {
      let tabS = ""
      v.tabTitleList.forEach(e => {
        tabS += ((_.has(record, e)) ? (_.get(record, e)) : (e))
      });
      document.title = tabS
    }
    return s
  }
};


const optionRendererExtended = (record, v, prefix) => {
  let s = ""
  v.optionList.forEach(e => {
    const textBefore = e.textBefore === undefined ? "" : e.textBefore
    const textAfter = e.textAfter === undefined ? "" : e.textAfter
    const usePrefix = e.usePrefix === undefined ? false : e.usePrefix
    const source = e.source
    s += textBefore
    if (source) {
      const nsource = usePrefix ? (prefix + '.' + source) : source
      s += ((_.has(record, nsource)) ? (_.get(record, nsource)) : (nsource))
    }
    s += textAfter
  });
  return s
}


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});


const uploadAndConnectFile = (file, v, dataProvider, resourceName = 'UploadStaticFile') => new Promise((resolve, reject) => {
  if (v.props.multiple) {
    let arr = []
    file.forEach(val => {
      if (!_.has(val, 'uploaded')) {
        toBase64(_.has(val, 'rawFile') ? val.rawFile : val).then(result => {
          dataProvider.create(resourceName, { fileData: result, fileName: val.name })
            .then((result) => {
              arr.push(result)
            })
        }).catch(error => console.log('b64 error file', error, val))
      } else {
        arr.push(val)
      }
    })
    let refreshIntervalId = setInterval(() => {
      if (arr.length === file.length) {
        clearInterval(refreshIntervalId)
        resolve(arr)
      }
    }, 300);
  } else {
    if (!_.has(file, 'uploaded')) {
      toBase64(_.has(file, 'rawFile') ? file.rawFile : file)
        .then(result => {
          dataProvider.create(resourceName, { fileData: result, fileName: file.name })
            .then((result) => {
              resolve([result])
            })
            .catch((error) => {
              reject(error)
            })
        }).catch((error) => console.log('b64 error file', error, file))
    }
  }
})


const filterByShowCondition = (arr, sourcePrefix, form) => {
  return arr.filter(v => {
    if (v.show_conditions === undefined) {
      const show_condition = v.show_condition
      if (show_condition === undefined) {
        return true
      } else {
        const cond_prop = ((show_condition.propertyWithPrefix === true) ? (sourcePrefix + '.' + show_condition.property) : (show_condition.property))
        try {
          const cond_val = form.getValues(cond_prop);
          return (show_condition.not && !show_condition.value.includes(cond_val)) || (!show_condition.not && show_condition.value.includes(cond_val))
        } catch (error) {
          console.log('-=-=-=-=-=-=- v2.2.1 ERROR', error)
          return false
        }
      }
    } else {
      let all_cond = true
      v.show_conditions.forEach(show_condition => {
        if (all_cond) {
          const cond_prop = ((show_condition.propertyWithPrefix === true) ? (sourcePrefix + '.' + show_condition.property) : (show_condition.property))
          try {
            const cond_val = form.getValues(cond_prop);
            all_cond = (show_condition.not && !show_condition.value.includes(cond_val)) || (!show_condition.not && show_condition.value.includes(cond_val))
          } catch (error) {
            console.log('-=-=-=-=-=-=- v2.2.2 ERROR', error)
            all_cond = false
          }
        }
      })
      return all_cond
    }
  });
}


const disableChecks = (v, form) => {
  let all_cond = false
  if (v.disabledChecks !== undefined) {
    v.disabledChecks.forEach(disable_condition => {
      if (!all_cond) {
        const cond_prop = disable_condition.field
        try {
          const cond_val = form.getValues(cond_prop);
          all_cond = (disable_condition.not && !disable_condition.value.includes(cond_val)) || (!disable_condition.not && disable_condition.value.includes(cond_val))
        } catch (error) {
          console.log('-=-=-=-=-=-=- v3.1.1 ERROR', error)
          all_cond = false
        }
      }
    });
  }
  return all_cond
}


const SelectListSubRef = (props) => {
  let filter = {}
  if (props.linked !== undefined && props.linked) {
    Object.keys(props.linked).forEach(v => {
      filter[v] = props.linked[v]
    })
  }
  if (props.filter !== undefined && props.filter) {
    Object.keys(props.filter).forEach(v => {
      filter[v] = props.filter[v]
    })
  }
  const { data, total, isLoading, error, refetch } = useGetList(
    props.reference,
    {
      pagination: props.pagination === undefined ? {} : props.pagination,
      sort: props.sort === undefined ? {} : props.sort,
      filter
    }
  );
  if (isLoading) { return <Loading />; }
  if (error) { return <p>ERROR</p>; }
  let listChoices = []
  data.forEach(v => {
    const field = _.get(v, props.field, [])
    if (field) {
      field.forEach(v2 => {
        let vid = _.get(v2, props.fieldId);
        if (isString(vid)) {
          vid = vid.replaceAll('\\', '').replaceAll('"', '\\"')
        }
        listChoices.push({
          id: vid,
          name: _.get(v2, props.fieldName)
        })
      })
    }
  })
  return (
    (props.inputType === "SelectInput" || props.inputType === undefined || !props.inputType) ? (<SelectInput {...props.inputProps} choices={listChoices} source={props.source} />) : (null)
      (props.inputType === "SelectArrayInput") ? (<SelectArrayInput {...props.inputProps} choices={listChoices} source={props.source} />) : (null)
        (props.inputType === "DualListInput") ? (<DualListInput {...props.inputProps} choices={listChoices} source={props.source} />) : (null)
          (props.inputType === "CheckboxGroupInput") ? (<CheckboxGroupInput {...props.inputProps} choices={listChoices} source={props.source} />) : (null)
            (props.inputType === "RadioButtonGroupInput") ? (<RadioButtonGroupInput {...props.inputProps} choices={listChoices} source={props.source} />) : (null)
  )
}


const makeObj = (arr, record) => {
  let obj = {}
  if (arr !== undefined && arr !== null) {
    arr.forEach(v => {
      if (v.recordField) {
        _.set(obj, v.field, _.get(record, v.recordField))
      } else {
        _.set(obj, v.field, v.value)
      }
    });
  }
  return obj
}


export const BuildFields = (props) => {
  const [isChildLoading, setChildLoading] = useState(false);
  const simpleIteratorFilters = props.simpleIteratorFilters;
  const setSimpleIteratorFilters = props.setSimpleIteratorFilters;
  const [newCreateReferenceValue, setNewCreateReferenceValue] = useState();
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const form = useFormContext()
  const [theme, setTheme] = useTheme();
  const log = props.log === true;
  const subsDepth = ((props.subsDepth === undefined) ? (0) : (props.subsDepth))
  const autoCompleteOff = ((props.autoComplete === undefined) ? ("off") : (props.autoComplete))
  const fields = filterByShowCondition(props.fields, props.source, form)
  const useSourcePrefix = props.useSourcePrefix === true;
  let allDisabled = (props.allDisabled === undefined) ? false : props.allDisabled;
  if (props.edit !== undefined && props.edit.allDisabledChecks !== undefined) {
    props.edit.allDisabledChecks.forEach(disable_condition => {
      if (!allDisabled) {
        const cond_prop = disable_condition.field
        try {
          const cond_val = form.getValues(cond_prop) || _.get(record, cond_prop);
          allDisabled = (disable_condition.not && !disable_condition.value.includes(cond_val)) || (!disable_condition.not && disable_condition.value.includes(cond_val))
        } catch (error) {
          console.log('-=-=-=-=-=-=- v3.1.1 ERROR', error)
          allDisabled = false
        }
      }
    });
  }
  let linked = [];
  return fields.map((v, i) => {
    const nsource = ((useSourcePrefix) ? (props.source + '.' + v.source) : (v.source));
    if (v.hidden !== true) {
      linked[nsource] = (v.props === undefined || v.props.filter === undefined) ? ({}) : (v.props.filter);
      if (v.linked !== undefined) {
        let linkedValue = form.getValues(((v.linked.useSourcePrefix) ? (props.source + '.' + v.linked.source) : (v.linked.source)));
        try {
          linkedValue = useWatch({ name: ((v.linked.useSourcePrefix) ? (props.source + '.' + v.linked.source) : (v.linked.source)) });
        } catch (error) {
          console.log('-=-=-=-=-=-=- BuildFields linked', error, v.linked)
        }
        if (linkedValue !== null && linkedValue !== undefined) {
          linked[nsource][v.linked.field] = (v.linked.in === true) ? ([linkedValue]) : (linkedValue)
        }
      }
      if (v.linkedList !== undefined) {
        v.linkedList.forEach(linkedItem => {
          let linkedValue = form.getValues(((linkedItem.useSourcePrefix) ? (props.source + '.' + linkedItem.source) : (linkedItem.source)));
          try {
            linkedValue = useWatch({ name: ((linkedItem.useSourcePrefix) ? (props.source + '.' + linkedItem.source) : (linkedItem.source)) });
          } catch (error) {
            console.log('-=-=-=-=-=-=- BuildFields linkedList item', error, linkedItem)
          }
          if (linkedValue !== null && linkedValue !== undefined) {
            linked[nsource][linkedItem.field] = (linkedItem.in === true) ? ([linkedValue]) : (linkedValue)
          }
        });
      }
      if (v.type === 'simple_array_input') {
        let arr = [];
        let index_shift = v.indexShift === undefined ? 0 : v.indexShift;
        for (let index = 0; index < v.size; index++) {
          arr.push(index)
        }
        return arr.map((v2, i2) => {
          return <TextInput readOnly={disableChecks(v, form) || allDisabled} {...v.props}
            label={((v.labels === undefined) ? (v.label) : (v.labels[v2]))}
            source={nsource + '.' + (v2 + index_shift)}
            autoComplete={autoCompleteOff}
            key={i + '_' + v2}
          />
        })
      }
      else if (v.type === 'editable_datagrid') {
        const localData = record.json_values.products
        const CreateForm = () => (
          <RowForm key={i}>
            {v.createFields.map((v, i) => {
              <BuildFields fields={[v]} key={i} subsDepth={subsDepth + 1} log={false} useSourcePrefix={useSourcePrefix} />
            })}
          </RowForm>
        );
        const EditForm = () => (
          <RowForm key={i}>
            <BuildFields fields={v.editFields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={useSourcePrefix} />
          </RowForm>
        );
        return (
          <LocalListController data={localData} key={i}>
            <EditableDatagrid createForm={v.createFields === undefined ? undefined : <CreateForm />} editForm={v.editFields === undefined ? undefined : <EditForm />} >
              {v.listFields.map((v, i) => {
                if (v.type === "date") {
                  return <DateField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === "boolean") {
                  return <BooleanField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === "reference_field") {
                  return <ReferenceField {...v.props} source={v.source} key={i} >
                    <FunctionField render={(e) => optionRenderer(e, v)} />
                  </ReferenceField>
                }
                else if (v.type === "reference_array_field") {
                  return <ReferenceArrayField {...v.props} source={v.source} key={i} link={false} >
                    <SingleFieldList linkType={false}>
                      <ChipField {...v.propsChip} />
                    </SingleFieldList>
                  </ReferenceArrayField>
                }
                else if (v.type === "text") {
                  return <TextField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === "text_input") {
                  return <TextInput {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'typography') {
                  return <Typography variant="body1" gutterBottom {...v.props} key={i}>
                    <FunctionField render={(e) => optionRenderer(e, v)} />
                  </Typography>
                }
                else if (v.type === "number") {
                  return <NumberField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === "data_grid_chip_translatable") {
                  return <DataGridChipTranslatable {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'data_grid_icon_translatable') {
                  return <DataGridIconTranslatable {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'photo_field') {
                  return <PhotoField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'reference_link') {
                  return <ReferenceField {...v.props} source={v.source} key={i}>
                    {(v.icon !== undefined) ? (createElement(MuiIcons[v.icon])) : (optionRenderer(record, v))}
                  </ReferenceField>
                }
                else {
                  return (v.hidden === true) ? (null) : (<TextField source={v.name} label={v.label} key={i} />)
                }
              })}
            </EditableDatagrid>
            <Pagination />
          </LocalListController>
        );
      }
      else if (v.type === 'simple_array_number_input') {
        let arr = [];
        let index_shift = v.indexShift === undefined ? 0 : v.indexShift;
        for (let index = 0; index < v.size; index++) {
          arr.push(index)
        }
        return arr.map((v2, i2) => {
          return <NumberInput readOnly={disableChecks(v, form) || allDisabled}
            {...v.props}
            label={((v.labels === undefined) ? (v.label) : (v.labels[v2]))}
            source={nsource + '.' + (v2 + index_shift)}
            autoComplete={autoCompleteOff}
            key={i + '_' + v2}
            onFocus={(e) => e.target.addEventListener("wheel", (e) => { e.preventDefault() }, { passive: false })}
          />
        })
      }
      else if (v.type === 'label') {
        if (_.has(v, 'props.reference')) {
          return <ReferenceField {...v.props} source={nsource} key={i} >
            <FunctionField render={(e) => optionRenderer(e, v)} />
          </ReferenceField>
        } else {
          return <TextField {...v.props} source={nsource} key={i} />
        }
      }
      else if (v.type === 'text') {
        return <TextInput readOnly={disableChecks(v, form) || allDisabled} {...v.props} source={nsource} autoComplete={autoCompleteOff} key={i} />
      }
      else if (v.type === 'json_field_file') {
        return <>
          {(isChildLoading) ? (
            <Loading style={{ marginLeft: "auto", marginRight: "auto" }} />
          ) : (
            <FileInput readOnly={disableChecks(v, form) || allDisabled} {...v.props}
              onChange={(im) => {
                setChildLoading(true);
                uploadAndConnectFile(im, v, dataProvider, (v.resourceName === undefined || v.resourceName === null) ? ("UploadStaticFile") : (v.resourceName))
                  .then(e => {
                    setChildLoading(false);
                    form.setValue(nsource + '__upload_results', e)
                  })
                  .catch(error => {
                    setChildLoading(false);
                    console.log('UPLOAD ERROR', error)
                  })
              }}
              source={nsource} key={i}>
              <FileField {...v.propsFileField} />
            </FileInput>
          )}
        </>;
      }
      else if (v.type === 'json_field_file_image') {
        return <>
          {(isChildLoading) ? (
            <Loading style={{ marginLeft: "auto", marginRight: "auto" }} />
          ) : (
            <ImageInput readOnly={disableChecks(v, form) || allDisabled} {...v.props}
              onChange={(im) => {
                setChildLoading(true);
                uploadAndConnectFile(im, v, dataProvider, (v.resourceName === undefined || v.resourceName === null) ? ("UploadStaticFile") : (v.resourceName))
                  .then(e => {
                    setChildLoading(false);
                    form.setValue(nsource + '__upload_results', e)
                  })
                  .catch(error => {
                    setChildLoading(false);
                    console.log('UPLOAD ERROR', error)
                  })
              }}
              source={nsource} key={i}>
              {v.disableShow ? (null) : (<ImageField {...v.propsFileField} />)}
            </ImageInput>
          )}
        </>;
      }
      else if (v.type === 'number') {
        return <NumberInput
          readOnly={disableChecks(v, form) || allDisabled}
          {...v.props}
          source={nsource}
          autoComplete={autoCompleteOff}
          key={i}
          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
        />
      }
      else if (v.type === 'kitapp_number') {
        return <KitAppNumber
          readOnly={disableChecks(v, form) || allDisabled}
          {...v.props}
          form={form}
          transformation={v.transformation ? v.transformation : ""}
          source={nsource}
          autoComplete={autoCompleteOff}
          key={i}
          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
        />
      }
      else if (v.type === 'typography') {
        return <Typography variant="h6" gutterBottom {...v.props} key={i}>
          {optionRenderer(record, v)}
        </Typography>
      }
      else if (v.type === 'bool') {
        return <BooleanInput readOnly={disableChecks(v, form) || allDisabled} {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'bool_field') {
        return <BooleanField {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'date') {
        return <KitAppMinMaxDateOrTimeInput readOnly={disableChecks(v, form) || allDisabled} type="DateInput" v={v} nsource={nsource} key={i} />
      }
      else if (v.type === 'datetime') {
        return <KitAppMinMaxDateOrTimeInput readOnly={disableChecks(v, form) || allDisabled} type="DateTimeInput" v={v} nsource={nsource} key={i} />
      }
      else if (v.type === 'time') {
        let time_val = _.get(record, nsource)
        let typeof_time = typeof time_val
        if (typeof_time === 'string') {
          _.set(record, nsource, time_val.slice(0, 5))
        }
        return <TimeInput readOnly={disableChecks(v, form) || allDisabled} {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'select') {
        return <SelectInput readOnly={disableChecks(v, form) || allDisabled} {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'referecne_select') {
        return <ReferenceInput {...v.props} filter={linked[nsource]} source={nsource + '.id'} key={i}>
          <SelectInput readOnly={disableChecks(v, form) || allDisabled} {...v.propsSelectInput} />
        </ReferenceInput>
      }
      else if (v.type === 'referecne_autocomplete') {
        const getNewValue = (props) => {
          setNewCreateReferenceValue(optionRenderer(props, v))
          form.setValue(nsource, props.id);
        }
        return (
          newCreateReferenceValue ?
            <Labeled label={v.propsAutocompleteInput.label ? v.propsAutocompleteInput.label : nsource} key={i}>
              <Typography {...v.propsTypography} variant="h6" gutterBottom>{newCreateReferenceValue}</Typography>
            </Labeled>
            :
            <ReferenceInput {...v.props} source={nsource} filter={linked[nsource]} key={i}>
              <AutocompleteInput
                readOnly={disableChecks(v, form) || allDisabled}
                {...v.propsAutocompleteInput}
                optionText={(e) => {
                  return optionRenderer(e, v)
                }}
                onChange={(val, val2) => {
                  if (v.setOnChange !== undefined && v.setOnChange !== null) {
                    if (typeof v.setOnChange === typeof []) {
                      v.setOnChange.forEach(v2 => {
                        console.log(' -=-=-=-=-=-=-=-> SETONCHANGE', v2.useSourcePrefix ? (props.source + '.' + v2.fieldToSet) : v2.fieldToSet, ' to ', v2.selectedObjectField, ' VAL=', _.get(val2, v2.selectedObjectField))
                        form.setValue(v2.useSourcePrefix ? (props.source + '.' + v2.fieldToSet) : v2.fieldToSet, _.get(val2, v2.selectedObjectField))
                      });
                    } else {
                      console.log(' -=-=-=-=-=-=-=-> SETONCHANGE', v.useSourcePrefix ? (props.source + '.' + v.setOnChange.fieldToSet) : v.setOnChange.fieldToSet, ' to ', v.setOnChange.selectedObjectField, 'VAL=', _.get(val2, v.setOnChange.selectedObjectField))
                      form.setValue(v.useSourcePrefix ? (props.source + '.' + v.setOnChange.fieldToSet) : v.setOnChange.fieldToSet, _.get(val2, v.setOnChange.selectedObjectField))
                    }
                  }
                }}
                validate={v.propsAutocompleteInput.required ? required() : ""}
                filterToQuery={(text) => filterToQuery(text, v)}
                create={(v.create === undefined) ? (null) : (<CreateReference {...v} getNewValue={(val) => getNewValue(val)} />)}
              />
            </ReferenceInput>
        )
      }
      else if (v.type === 'referecne_select_many') {
        return <ReferenceArrayInput {...v.props} filter={linked[nsource]} source={nsource} key={i}>
          <SelectArrayInput readOnly={disableChecks(v, form) || allDisabled} {...v.props2} source={nsource} filter={linked[nsource]} optionText={(e) => optionRenderer(e, v)} key={i} />
        </ReferenceArrayInput>
      }
      else if (v.type === 'referecne_autocomplete_many') {
        return <ReferenceArrayInput {...v.props} filter={linked[nsource]} source={nsource} key={i}>
          <AutocompleteArrayInput readOnly={disableChecks(v, form) || allDisabled} {...v.props2} optionText={(e) => optionRenderer(e, v)} />
        </ReferenceArrayInput>
      }
      else if (v.type === 'rich_text_input') {
        return <SmartRichTextInput readOnly={disableChecks(v, form) || allDisabled} {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'simple_iterator') {
        let sisisifilter = undefined;
        console.log('-=-=-=-=-=-=-=-=-=- v.filters=', v.filters, ' simpleIteratorFilters=', simpleIteratorFilters, '-=-=-=-=-- sisisifilter=', sisisifilter)
        let outputedCount = 0;
        const arrIndexesList = v.customIterator && _.get(record, nsource)?.map((v2, i2) => {
          return v.filters?.filter((v3, i3) => {
            return v3.source === undefined || [undefined, _.get(v2, v3.source), ''].includes(_.get(simpleIteratorFilters, v3.source))
          }).length === v.filters?.filter((v3, i3) => true).length;
        });
        console.log('-=-=-=-=-=-=-=-=-=-=-=- arrList=', arrIndexesList);
        return <>
          {
            v.customIterator && v.filters?.map((v2, i2) => {
              sisisifilter = useWatch({ name: `simple_iterator_${nsource}_filter${v2.source}` })
              if (v2.type === "text") {
                return (<TextInput {...v2.props} source={`simple_iterator_${nsource}_filter${v2.source}`} key={i2} />)
              }
              else if (v2.type === "reference_select") {
                return (<ReferenceInput {...v2.props} source={`simple_iterator_${nsource}_filter${v2.source}`} key={i2}>
                  <SelectInput {...v2.propsSelectInput}
                    optionText={(e) => optionRenderer(e, v2)}
                    onChange={(val, rec) => { let rsifbuf = simpleIteratorFilters; _.set(rsifbuf, v2.source, val.target?.value); console.log('-=-=-=-=-=- onChange rsifbuf=', rsifbuf); setSimpleIteratorFilters(rsifbuf); }} />
                </ReferenceInput>)
              }
              else if (v2.type === "referecne_autocomplete") {
                return (<ReferenceInput {...v2.props} source={`simple_iterator_${nsource}_filter${v2.source}`} key={i2}>
                  <AutocompleteInput
                    {...v2.propsAutocompleteInput}
                    optionText={(e) => optionRenderer(e, v2)}
                    filterToQuery={(text) => filterToQuery(text, v2)}
                    onChange={(val, rec) => { let rsifbuf = simpleIteratorFilters; _.set(rsifbuf, v2.source, val); console.log('-=-=-=-=-=- onChange rsifbuf=', rsifbuf); setSimpleIteratorFilters(rsifbuf); }}
                    // onInputChange={(e) => { console.log('-=-=-=-=-=-onInputChange e=', e); if (e !== null) { console.log('-=-=-=-=-=-onInputChange event.currentTarget val', e.currentTarget.value); } }}
                    // onSelect={(e) => { let rsifbuf = simpleIteratorFilters; _.set(rsifbuf, v2.source, form.getValues(v2.source)); console.log('-=-=-=-=-=- rsifbuf=', rsifbuf); setSimpleIteratorFilters(rsifbuf); }}
                    onReset={(e) => { let rsifbuf = simpleIteratorFilters; _.set(rsifbuf, v2.source, ''); console.log('-=-=-=-=-=- onReset rsifbuf=', rsifbuf); setSimpleIteratorFilters(rsifbuf); }}
                  />
                </ReferenceInput>)
              }
              else if (v2.type === "referecne_select_many") {
                return (<ReferenceArrayInput {...v2.props} source={`simple_iterator_${nsource}_filter${v2.source}`} key={i2}>
                  <SelectArrayInput {...v2.propsInput} source={`simple_iterator_${nsource}_filter${v2.source}`} optionText={(e) => optionRenderer(e, v2)} key={i} />
                </ReferenceArrayInput>)
              }
              else if (v2.type === "referecne_autocomplete_many") {
                /*let linked = (v2.props === undefined || v2.props.filter === undefined) ? ({}) : (v2.props.filter);
                if (v2.linked !== undefined) {
                  let linkedValue = _.get(listParams[0].filterValues, v2.linked.source)
                  if (linkedValue !== null && linkedValue !== undefined) {
                    linked[v2.linked.field] = (v2.linked.in === true) ? ([linkedValue]) : (linkedValue)
                  }
                }*/
                return (<ReferenceArrayInput {...v2.props} source={`simple_iterator_${nsource}_filter${v2.source}`} /*filter={linked}*/ key={i2}>
                  <AutocompleteArrayInput {...v2.propsAutocompleteInput} optionText={(e) => optionRenderer(e, v2)} />
                </ReferenceArrayInput>)
              }
              else if (v2.type === "select") {
                return (<SelectInput {...v2.props} source={`simple_iterator_${nsource}_filter${v2.source}`} key={i2} />)
              }
              else if (v2.type === "date") {
                return (<DateInput {...v2.props} source={`simple_iterator_${nsource}_filter${v2.source}`} key={i2} />)
              }
              else if (v2.type === "select_list_sub_reference") {
                return (<SelectListSubRef {...v2.props} source={`simple_iterator_${nsource}_filter${v2.source}`} key={i2} />)
              }
              else {
                return null
              }
            })
          }
          {!v.customIterator && <ArrayInput {...v.props} source={nsource} key={i}>
            <SimpleFormIterator readOnly={disableChecks(v, form) || allDisabled} {...v.simpleFormIteratorProps}>
              <BuildFields fields={v.fields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={true} allDisabled={allDisabled} />
            </SimpleFormIterator>
          </ArrayInput>}
          {v.customIterator && <div key={i}>Длина: |{arrIndexesList?.filter(vel => vel).length}| ТУТ ВСТАВИТЬ ПАГИНАЦИЮ НАВЕРНОЕ ЩА ВЫВОДИМ ПЕРВЫЕ 10</div>}
          {v.customIterator && <div key={i}>
            {_.get(record, nsource)?.map((v2, i2) => {
              console.log('-=-=-=-=-=-=- v2=', v2, ' outputedCount=', outputedCount);
              return ((arrIndexesList[i2] && outputedCount++ < 10) ? <Stack direction="row" spacing={2} key={`k${i}_${i2}`}>
                <BuildFields fields={v.fields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={true} allDisabled={allDisabled} source={`${nsource}.${i2}`} />
              </Stack> : null);
            })}
          </div>}
        </>
      }
      else if (v.type === 'reference_many_iterator') {
        return <ReferenceManyInput {...v.props} source={nsource} key={i}>
          <SimpleFormIterator readOnly={disableChecks(v, form) || allDisabled} {...v.simpleFormIteratorProps}>
            <TextInput style={{ display: "none" }} source={v.reference_field} defaultValue={record.id} />
            {v.default_values.map((v2, i2) => {
              return <TextInput style={{ display: "none" }} source={v2.field} defaultValue={v2.value} key={v2 + '_' + i2} />
            })}
            <BuildFields fields={v.fields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={true} allDisabled={allDisabled} />
          </SimpleFormIterator>
        </ReferenceManyInput>
      }
      else if (v.type === 'card_media') {
        return <Card {...v.props} key={i}>
          <CardMedia {...v.propsMedia} />
        </Card>
      }
      else if (v.type === 'photo_field') {
        return <PhotoField {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'link') {
        return <>
          <a {...v.props} href={'/order_report/' + record.uuid + v.url_suffix} key={i + "_1"}>{v.label}</a> | <a {...v.props} href={'/order_report/' + record.uuid + v.url_suffix + '?attachment=1'} key={i + "_2"} >Скачать</a>
        </>
      }
      else if (v.type === 'aria_label') {
        return <Tooltip {...v.props} title={<React.Fragment><div dangerouslySetInnerHTML={{ __html: optionRendererExtended(record, v, props.source) }} /></React.Fragment>} key={i}>
          <IconButton {...v.propsIconButton}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      }
      else if (v.type === 'icon_required') {
        return <AcUnitIcon {...v.props} key={i} />
      }
      else if (v.type === 'box') {
        return <Box {...v.props} key={i}>
          <BuildFields fields={v.fields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={useSourcePrefix} allDisabled={allDisabled} />
        </Box>
      }
      else if (v.type === 'grid') {
        return <Grid {...v.props} key={i}>
          <BuildFields fields={v.fields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={useSourcePrefix} allDisabled={allDisabled} />
        </Grid>
      }
      else if (v.type === 'stack') {
        return <Stack {...v.props} key={i}>
          <BuildFields fields={v.fields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={useSourcePrefix} allDisabled={allDisabled} />
        </Stack>
      }
      else if (v.type === 'list_reference_many_field') {
        const customEditToolbar = (v.customEditToolbar === undefined) ? (undefined) : (v.customEditToolbar);
        const bulkActions = (v.bulkActions === undefined) ? ([]) : (v.bulkActions)
        const actionButtons = (v.actions === undefined) ? ([]) : (v.actions)
        const expandFields = (v.expandFields === undefined) ? (undefined) : (v.expandFields);
        const filters = (v.filters === undefined) ? ([]) : (v.filters)
        const CreateDialog = () => {
          return <TopToolbar>
            <CreateInDialogButton fullWidth maxWidth="md" {...v.propsCreateInDialogButton} record={makeObj(v.createDefaultValues, record)} >
              <SimpleForm>
                <BuildFields fields={v.createFields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={useSourcePrefix} />
              </SimpleForm>
            </CreateInDialogButton>
          </TopToolbar>
        }
        const Toolbar = () => {
          return <TopToolbar>
            {actionButtons.map((v, i) => {
              if (v.type === "createInDialog") {
                return (
                  <CreateInDialogButton fullWidth maxWidth="xl" key={i} label={(v.label === undefined) ? (null) : (v.label)} record={makeObj(v.createDefaultValues, record)}>
                    <SimpleForm {...v.formProps}>
                      <BuildFields props={v} fields={v.fields} />
                    </SimpleForm>
                  </CreateInDialogButton>
                )
              }
            })}
          </TopToolbar>
        }
        return (
          <List
            sx={{ width: "100%" }}
            {...v.props}
            aside={(v.filterSidebar === undefined) ? (null) : (<FilterSidebar {...v.filterSidebar} />)}
            pagination={<Pagination rowsPerPageOptions={(v.rowsPerPageOptions === undefined) ? ([10, 25, 50, 100]) : (v.rowsPerPageOptions)} {...v.paginatorProps} />}
            perPage={(v.perPage === undefined) ? (50) : (v.perPage)}
            actions={
              v.actions ? (<Toolbar />) : (
                (v.createFields === undefined || v.createFields.length === 0) ? (<></>) : (<CreateDialog />)
              )
            }
            empty={
              v.actions ? (<Toolbar />) : (
                (v.createFields === undefined || v.createFields.length === 0) ? (<></>) : (<CreateDialog />)
              )
            }
            filter={makeObj(v.listFilters, record)}
            filters={
              filters.map((v2, i2) => {
                if (v2.type === "text") {
                  return (<TextInput {...v2.props} source={v2.source} key={i2} />)
                }
                else if (v2.type === "reference_select") {
                  return (<ReferenceInput {...v2.props} source={v2.source} key={i2}>
                    <SelectInput {...v2.propsSelectInput} />
                  </ReferenceInput>)
                }
                else if (v2.type === "referecne_autocomplete") {
                  return (<ReferenceInput {...v2.props} source={v2.source} key={i2}>
                    <AutocompleteInput
                      {...v2.propsAutocompleteInput}
                      optionText={(e) => optionRenderer(e, v2)}
                      filterToQuery={(text) => filterToQuery(text, v)}
                    />
                  </ReferenceInput>)
                }
                else if (v2.type === "referecne_select_many") {
                  return (<ReferenceArrayInput {...v2.props} source={v2.source} key={i2}>
                    <SelectArrayInput {...v2.propsInput} source={v2.source} optionText={(e) => optionRenderer(e, v2)} />
                  </ReferenceArrayInput>)
                }
                else if (v2.type === "referecne_autocomplete_many") {
                  return (<ReferenceArrayInput {...v2.props} source={v2.source} key={i2}>
                    <AutocompleteArrayInput {...v2.propsAutocompleteInput} optionText={(e) => optionRenderer(e, v2)} />
                  </ReferenceArrayInput>)
                }
                else if (v2.type === "select") {
                  return (<SelectInput {...v2.props} source={v2.source} key={i2} />)
                }
                else if (v2.type === "date") {
                  return (<DateInput {...v2.props} source={v2.source} key={i2} />)
                }
                else if (v2.type === "select_list_sub_reference") {
                  return (<SelectListSubRef {...v2.props} source={v2.source} key={i2} />)
                }
                else {
                  return null
                }
              })
            }
            key={i}
          >
            <Datagrid
              bulkActionButtons={<BulkActionButtons actions={bulkActions} />}
              isRowExpandable={(v.isRowExpandable === undefined) ? (null) : ((record) => {
                if (v.isRowExpandable.const !== undefined) {
                  return v.isRowExpandable.const
                } else {
                  return _.get(record, v.isRowExpandable.field, v.isRowExpandable.defaultValue) === v.isRowExpandable.valueCondition
                }
              })}
              isRowSelectable={(v.isRowSelectable === undefined) ? (null) : ((record) => {
                if (v.isRowSelectable.const !== undefined) {
                  return v.isRowSelectable.const
                } else {
                  return _.get(record, v.isRowSelectable.field, v.isRowSelectable.defaultValue) === v.isRowSelectable.valueCondition
                }
              })}
              expand={(expandFields === undefined) ? (null) : (<ExpandRender fields={expandFields} />)} rowSx={record => {
                if (v.rowListStyle === undefined) {
                  return null
                } else {
                  return { backgroundColor: v.rowListStyle.backgrounds[_.get(record, v.rowListStyle.field)] }
                }
              }}
            >
              {v.listFields.map((v, i) => {
                if (v.type === "date") {
                  return <DateField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === "boolean") {
                  return <BooleanField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === "reference_field") {
                  return <ReferenceField {...v.props} source={v.source} key={i} >
                    <FunctionField render={(e) => optionRenderer(e, v)} />
                  </ReferenceField>
                }
                else if (v.type === "reference_array_field") {
                  return <ReferenceArrayField {...v.props} source={v.source} key={i} link={false} >
                    <SingleFieldList linkType={false}>
                      <ChipField {...v.propsChip} />
                    </SingleFieldList>
                  </ReferenceArrayField>
                }
                else if (v.type === "text") {
                  return <TextField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'typography') {
                  return <Typography variant="body1" gutterBottom {...v.props} key={i}>
                    <FunctionField render={(e) => optionRenderer(e, v)} />
                  </Typography>
                }
                else if (v.type === "number") {
                  return <NumberField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === "data_grid_chip_translatable") {
                  return <DataGridChipTranslatable {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'data_grid_icon_translatable') {
                  return <DataGridIconTranslatable {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'photo_field') {
                  return <PhotoField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'reference_link') {
                  return <ReferenceField {...v.props} source={v.source} key={i}>
                    {(v.icon !== undefined) ? (createElement(MuiIcons[v.icon])) : (optionRenderer(record, v))}
                  </ReferenceField>
                }
                else {
                  return (v.hidden === true) ? (null) : (<TextField source={v.name} label={v.label} key={i} />)
                }
              })}
              {(v.editFields === undefined || v.editFields.length === 0) ? (null) : (
                <EditInDialogButton fullWidth maxWidth="lg" {...v.propsEditInDialogButton}>
                  <SimpleForm defaultValues={makeObj(v.editDefaultValues, record)} toolbar={(customEditToolbar === undefined) ? (undefined) : (<EditToolbar {...customEditToolbar} />)}>
                    <BuildFields fields={v.editFields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={useSourcePrefix} />
                  </SimpleForm>
                </EditInDialogButton>
              )}
            </Datagrid>
          </List>
        )
      }
      else if (v.type === 'accordionForm') {
        return <Accordion {...v.props} key={i}>
          <AccordionSummary
            {...v.propsSummary}
            expandIcon={createElement((v.icon !== undefined) ? (MuiIcons[v.icon]) : (MuiIcons["ArrowDropDown"]))}>
            <Typography component="span">{v.label}</Typography>
          </AccordionSummary>
          <AccordionDetails {...v.propsDetails}>
            <BuildFields fields={v.fields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={useSourcePrefix} allDisabled={allDisabled} />
          </AccordionDetails>
        </Accordion>;
      }
      else if (v.type === 'checkbox_group_input') {
        return <CheckboxGroupInput readOnly={disableChecks(v, form) || allDisabled} {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'nullable_boolean_input') {
        return <NullableBooleanInput readOnly={disableChecks(v, form) || allDisabled} {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'password_input') {
        return <PasswordInput readOnly={disableChecks(v, form) || allDisabled} {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'radio_button_group_input') {
        return <RadioButtonGroupInput readOnly={disableChecks(v, form) || allDisabled} {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'url_field') {
        return <UrlField {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'select_field') {
        return <SelectField {...v.props} source={nsource} key={i} />
      }
      else if (v.type === 'reference_link') {
        return <ReferenceField {...v.props} source={nsource} key={i}>
          {(v.icon !== undefined) ? (createElement(MuiIcons[v.icon])) : (optionRenderer(record, v))}
        </ReferenceField>
      }
      else if (v.type === 'select_list_sub_reference') {
        return <SelectListSubRef {...v.props} source={nsource} linked={linked[nsource]} key={i} />
      }
      else if (v.type === 'static_html') {
        return <div {...v.props} dangerouslySetInnerHTML={{ __html: v.html }} key={i} />
      }
      else if (v.type === 'dynamic_html_from_record') {
        return <div {...v.props} dangerouslySetInnerHTML={{ __html: _.get(record, nsource) }} key={i} />
      }
      else if (v.type === 'graph_nivo_simple') {
        let gdata = (v.globalDataConst !== undefined) ? v.globalDataConst : undefined;
        gdata = (v.globalDataField !== undefined) ? (_.get(record, v.globalDataField)) : (gdata);
        if (gdata === undefined && v.globalDataList !== undefined) {
          if (v.globalDataList.const) {
            gdata = v.globalDataList.const;
          } else if (v.globalDataList.field) {
            gdata = _.get(record, v.globalDataList.field);
          } else if (v.globalDataList.filteredFromArrayObjects) {
            const filteredArr = _.get(record, v.globalDataList.filteredFromArrayObjects.fieldArray).filter((fel) => _.get(fel, v.globalDataList.filteredFromArrayObjects.fieldFilterName) === v.globalDataList.filteredFromArrayObjects.fieldFilterValue);
            gdata = (filteredArr.length > 0) ? _.get(filteredArr[0], v.globalDataList.filteredFromArrayObjects.fieldGetData) : undefined;
          }
          if (gdata && v.globalDataList.toObject) {
            gdata = gdata.map((v2) => {
              let rv2 = {};
              _.set(rv2, v.globalDataList.toObject.valToField, v2);
              return { ...v.globalDataList.toObject.props, ...rv2 };
            });
          }
          if (gdata && v.globalDataList.modifyObjectFields) {
            const tempConstArr1 = v.globalDataList.modifyObjectFields.tempConstArr1;
            const tempConstArr2 = v.globalDataList.modifyObjectFields.tempConstArr2;
            const tempConstArr3 = v.globalDataList.modifyObjectFields.tempConstArr3;
            const tempArrFromRecord1 = (v.globalDataList.modifyObjectFields.tempArrFromRecord1) ? (_.get(record, v.globalDataList.modifyObjectFields.tempArrFromRecord1)) : (undefined);
            const tempArrFromRecord2 = (v.globalDataList.modifyObjectFields.tempArrFromRecord2) ? (_.get(record, v.globalDataList.modifyObjectFields.tempArrFromRecord2)) : (undefined);
            const tempArrFromRecord3 = (v.globalDataList.modifyObjectFields.tempArrFromRecord3) ? (_.get(record, v.globalDataList.modifyObjectFields.tempArrFromRecord3)) : (undefined);
            let buf;
            let tempArrFieldFilteredFromArrayObjects1;
            if (v.globalDataList.modifyObjectFields.filteredArr1) {
              buf = _.get(record, v.globalDataList.modifyObjectFields.filteredArr1.fieldArray).filter((fel) => _.get(fel, v.globalDataList.modifyObjectFields.filteredArr1.fieldFilterName) === v.globalDataList.modifyObjectFields.filteredArr1.fieldFilterValue);
              tempArrFieldFilteredFromArrayObjects1 = (buf.length > 0) ? _.get(buf[0], v.globalDataList.modifyObjectFields.filteredArr1.fieldGetData) : undefined;
            }
            gdata = gdata.map((v2, i2) => {
              let rv2 = v2;
              v.globalDataList.modifyObjectFields.fields.forEach((val) => {
                if (val.const) {
                  _.set(rv2, val.field, val.const);
                } else if (val.fromFieldByIndex) {
                  const arr = _.get(record, val.fromFieldByIndex);
                  _.set(rv2, val.field, (arr && arr.length >= i2) ? (arr[i2]) : (null));
                } else if (val.fromConstArr1) {
                  _.set(rv2, val.field, (tempConstArr1 && tempConstArr1.length >= i2) ? (tempConstArr1[i2]) : (null));
                } else if (val.fromConstArr2) {
                  _.set(rv2, val.field, (tempConstArr2 && tempConstArr2.length >= i2) ? (tempConstArr2[i2]) : (null));
                } else if (val.fromConstArr3) {
                  _.set(rv2, val.field, (tempConstArr3 && tempConstArr3.length >= i2) ? (tempConstArr3[i2]) : (null));
                } else if (val.fromTempArr1) {
                  _.set(rv2, val.field, (tempArrFromRecord1 && tempArrFromRecord1.length >= i2) ? (tempArrFromRecord1[i2]) : (null));
                } else if (val.fromTempArr2) {
                  _.set(rv2, val.field, (tempArrFromRecord2 && tempArrFromRecord2.length >= i2) ? (tempArrFromRecord2[i2]) : (null));
                } else if (val.fromTempArr3) {
                  _.set(rv2, val.field, (tempArrFromRecord3 && tempArrFromRecord3.length >= i2) ? (tempArrFromRecord3[i2]) : (null));
                } else if (val.fromFilteredArr1) {
                  _.set(rv2, val.field, (tempArrFieldFilteredFromArrayObjects1 && tempArrFieldFilteredFromArrayObjects1.length >= i2) ? (tempArrFieldFilteredFromArrayObjects1[i2]) : (null));
                }
              });
              return { ...v.globalDataList.modifyObjectFields.props, ...rv2 };
            });
          }
        }
        gdata = gdata || v.datalist?.map((v2, i2) => {
          let data = [];
          let xdata;
          let ydata;
          if (v2.dataConst) {
            data = v2.dataConst;
          } else if (v2.dataField) {
            data = _.get(record, v2.dataField);
          }
          if (v2.dataxConst) {
            xdata = v2.dataxConst;
          } else if (v2.dataxField) {
            xdata = _.get(record, v2.dataxField);
          } else if (v2.dataxFieldFilteredFromArrayObjects) {
            const filteredArr = _.get(record, v2.dataxFieldFilteredFromArrayObjects.fieldArray).filter((fel) => _.get(fel, v2.dataxFieldFilteredFromArrayObjects.fieldFilterName) === v2.dataxFieldFilteredFromArrayObjects.fieldFilterValue);
            xdata = (filteredArr.length > 0) ? _.get(filteredArr[0], v2.dataxFieldFilteredFromArrayObjects.fieldGetData) : undefined;
          }
          if (v2.datayConst) {
            ydata = v2.datayConst;
          } else if (v2.datayField) {
            ydata = _.get(record, v2.datayField);
          } else if (v2.datayFieldFilteredFromArrayObjects) {
            const filteredArr = _.get(record, v2.datayFieldFilteredFromArrayObjects.fieldArray).filter((fel) => _.get(fel, v2.datayFieldFilteredFromArrayObjects.fieldFilterName) === v2.datayFieldFilteredFromArrayObjects.fieldFilterValue);
            ydata = (filteredArr.length > 0) ? _.get(filteredArr[0], v2.datayFieldFilteredFromArrayObjects.fieldGetData) : undefined;
          }
          if (xdata) {
            v2.xdataReversed && xdata.reverse();
            xdata.forEach((xval, xind) => {
              (data.length <= xind) && data.push({ x: 0, y: 0 });
              data[xind].x = xval
            });
          }
          if (ydata) {
            v2.ydataReversed && ydata.reverse();
            ydata.forEach((xval, xind) => {
              (data.length <= xind) && data.push({ x: 0, y: 0 });
              data[xind].y = xval
            });
          }
          return { ...v2.props, id: v2.id, data: data }
        });
        return <div width={v.width || 500} height={v.height || 500} style={{ display: v.display || "block", width: v.width || 500, height: v.height || 500, margin: v.margin || 35 }} key={i}>
          {<NivoSimpleGraph data={gdata} v={v} currentTheme={theme} />}
        </div>;
      }
      else {
        return null
      }
    }
  })
}


export const FilterSidebar = (props) => (
  <Card {...props.cardProps}>
    <CardContent>
      {(props.savedQueriesList === undefined) ? (null) : (<SavedQueriesList {...props.savedQueriesList} />)}
      {(props.filterLiveSearch === undefined) ? (null) : (<FilterLiveSearch {...props.filterLiveSearch} />)}
      {(props.filterLists === undefined) ? (null) : (
        props.filterLists.map((v, i) => {
          return (<FilterList {...v.props} label={v.label} icon={createElement(MuiIcons[v.icon])} key={i} >
            {v.items.map((v2, i2) => {
              return (<FilterListItem {...v2.props} label={v2.label} value={v2.value} icon={(v2.icon === undefined) ? (null) : (createElement(MuiIcons[v2.icon]))} key={i2} />)
            })}
          </FilterList>)
        })
      )}
    </CardContent>
  </Card>
);


export const OpenSidebar = (props) => {
  const { list, record, onClose } = props;
  const { openedInSideBar } = list;
  return (<Card {...openedInSideBar.cardProps}>
    <CardContent {...openedInSideBar.cardContentProps}>
      <SimpleForm record={record} {...openedInSideBar.formProps}>
        {(openedInSideBar.showCloseButton !== true) ? (true) : (<Button {...openedInSideBar.closeButtonProps} onClick={() => { onClose(); }}>
          {(openedInSideBar.closeButtonIcon === undefined) ? (null) : (createElement(MuiIcons[openedInSideBar.closeButtonIcon]))}
        </Button>)}
        <BuildFields {...openedInSideBar.buildFieldProps} fields={openedInSideBar.fields} />
      </SimpleForm>
    </CardContent>
  </Card>);
};


const converter = (event) => {
  console.log(event)
  let d = Date.parse(event.created_at);
  d = d + 60 * 60 * 3;
  return {
    id: String(event.id),
    title: event.id,
    start: Date.parse(event.created_at),
    end: d,
    backgroundColor: '#0000ff',//colorForCategory(event.category),
    borderColor: '#0000ff',//colorForCategory(event.category),
    editable: false,
    // url: `https://meet.jit.si/${event.jitsi_meet_id}`,
  }
}


const customGetFilterValues = (dateInfo) =>
  dateInfo
    ? {
      created_at__gte: dateInfo.startStr,
      created_at__lte: dateInfo.endStr,
    }
    : {
      created_at__gte: new Date("2024-03-01"),
      created_at__lte: new Date("2024-03-31"),
    };


const exporter = (objects, sort, filterValues, selectedIds) => {
  // console.log(objects);
  // console.log('sort', sort);
  // console.log('filterValues', filterValues);
  // console.log('selectedIds', selectedIds);

  // const postsForExport = posts.map(post => {
  //   const { backlinks, author, ...postForExport } = post; // omit backlinks and author
  //   postForExport.author_name = post.author.name; // add a field
  //   return postForExport;
  // });
  // jsonExport(postsForExport, {
  //   headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
  // }, (err, csv) => {
  //   downloadCSV(csv, 'posts'); // download as 'posts.csv` file
  // });
};


const ExpandRender = (props) => {
  const record = useRecordContext();
  return <SimpleShowLayout>
    {props.fields.map((v, i) => {
      if (v.type === "date") {
        return <DateField {...v.props} source={v.source} key={i} />
      }
      else if (v.type === 'url_field') {
        return <UrlField {...v.props} source={v.source} key={i} />
      }
      else if (v.type === "boolean") {
        return <BooleanField {...v.props} source={v.source} key={i} />
      }
      else if (v.type === "reference_field") {
        return <ReferenceField {...v.props} source={v.source} key={i} >
          <FunctionField render={(e) => optionRenderer(e, v)} />
        </ReferenceField>
      }
      else if (v.type === "array_field") {
        return <ArrayField {...v.props} source={v.source} key={i}>
          <Datagrid bulkActionButtons={false}>
            {v.fields.map((field, i2) => {
              return <TextField source={field.name} label={field.label} key={i2} />
            })}
          </Datagrid>
        </ArrayField>
      }
      else if (v.type === "reference_array_field") {
        return <ReferenceArrayField {...v.props} source={v.source} key={i} link={false} >
          <SingleFieldList linkType={false}>
            <ChipField {...v.propsChip} />
          </SingleFieldList>
        </ReferenceArrayField>
      }
      else if (v.type === "text") {
        return <TextField {...v.props} source={v.source} key={i} />
      }
      else if (v.type === "number") {
        return <NumberField {...v.props} source={v.source} key={i} />
      }
      else if (v.type === "data_grid_chip_translatable") {
        return <DataGridChipTranslatable {...v.props} source={v.source} key={i} />
      }
      else if (v.type === 'photo_field') {
        return <PhotoField {...v.props} source={v.source} key={i} />
      }
      else if (v.type === 'rich_text_field') {
        return <RichTextField {...v.props} source={v.source} key={i} />
      }
      else if (v.type === 'list_reference_many_field') {
        const customEditToolbar = (v.customEditToolbar === undefined) ? (undefined) : (v.customEditToolbar);
        const bulkActions = (v.bulkActions === undefined) ? ([]) : (v.bulkActions)
        const expandFields = (v.expandFields === undefined) ? (undefined) : (v.expandFields);
        const filters = (v.filters === undefined) ? ([]) : (v.filters)
        const CreateDialog = () => {
          return <TopToolbar>
            <CreateInDialogButton fullWidth maxWidth="md" {...v.propsCreateInDialogButton} record={makeObj(v.createDefaultValues, record)} >
              <SimpleForm>
                <BuildFields fields={v.createFields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={useSourcePrefix} />
              </SimpleForm>
            </CreateInDialogButton>
          </TopToolbar>
        }
        return (
          <List
            sx={{ width: "100%" }}
            {...v.props}
            aside={(v.filterSidebar === undefined) ? (null) : (<FilterSidebar {...v.filterSidebar} />)}
            pagination={<Pagination rowsPerPageOptions={(v.rowsPerPageOptions === undefined) ? ([10, 25, 50, 100]) : (v.rowsPerPageOptions)} {...v.paginatorProps} />}
            perPage={(v.perPage === undefined) ? (50) : (v.perPage)}
            actions={(v.createFields === undefined || v.createFields.length === 0) ? (<></>) : (<CreateDialog />)}
            empty={(v.createFields === undefined || v.createFields.length === 0) ? (<></>) : (<CreateDialog />)}
            filter={makeObj(v.listFilters, record)}
            filters={
              filters.map((v2, i2) => {
                if (v2.type === "text") {
                  return (<TextInput {...v2.props} source={v2.source} key={i2} />)
                }
                else if (v2.type === "reference_select") {
                  return (<ReferenceInput {...v2.props} source={v2.source} key={i2}>
                    <SelectInput {...v2.propsSelectInput} />
                  </ReferenceInput>)
                }
                else if (v2.type === "referecne_autocomplete") {
                  return (<ReferenceInput {...v2.props} source={v2.source} key={i2}>
                    <AutocompleteInput
                      {...v2.propsAutocompleteInput}
                      optionText={(e) => optionRenderer(e, v2)}
                      filterToQuery={(text) => filterToQuery(text, v)}
                    />
                  </ReferenceInput>)
                }
                else if (v2.type === "referecne_select_many") {
                  return (<ReferenceArrayInput {...v2.props} source={v2.source} key={i2}>
                    <SelectArrayInput {...v2.propsInput} source={v2.source} optionText={(e) => optionRenderer(e, v2)} />
                  </ReferenceArrayInput>)
                }
                else if (v2.type === "referecne_autocomplete_many") {
                  return (<ReferenceArrayInput {...v2.props} source={v2.source} key={i2}>
                    <AutocompleteArrayInput {...v2.propsAutocompleteInput} optionText={(e) => optionRenderer(e, v2)} />
                  </ReferenceArrayInput>)
                }
                else if (v2.type === "select") {
                  return (<SelectInput {...v2.props} source={v2.source} key={i2} />)
                }
                else if (v2.type === "date") {
                  return (<DateInput {...v2.props} source={v2.source} key={i2} />)
                }
                else if (v2.type === "select_list_sub_reference") {
                  return (<SelectListSubRef {...v2.props} source={v2.source} key={i2} />)
                }
                else {
                  return null
                }
              })
            }
            key={i}
          >
            <Datagrid
              bulkActionButtons={<BulkActionButtons actions={bulkActions} />}
              isRowExpandable={(v.isRowExpandable === undefined) ? (null) : ((record) => {
                if (v.isRowExpandable.const !== undefined) {
                  return v.isRowExpandable.const
                } else {
                  return _.get(record, v.isRowExpandable.field, v.isRowExpandable.defaultValue) === v.isRowExpandable.valueCondition
                }
              })}
              isRowSelectable={(v.isRowSelectable === undefined) ? (null) : ((record) => {
                if (v.isRowSelectable.const !== undefined) {
                  return v.isRowSelectable.const
                } else {
                  return _.get(record, v.isRowSelectable.field, v.isRowSelectable.defaultValue) === v.isRowSelectable.valueCondition
                }
              })}
              expand={(expandFields === undefined) ? (null) : (<ExpandRender fields={expandFields} />)} rowSx={record => {
                if (v.rowListStyle === undefined) {
                  return null
                } else {
                  return { backgroundColor: v.rowListStyle.backgrounds[_.get(record, v.rowListStyle.field)] }
                }
              }}
            >
              {v.listFields.map((v, i) => {
                if (v.type === "date") {
                  return <DateField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === "boolean") {
                  return <BooleanField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === "reference_field") {
                  return <ReferenceField {...v.props} source={v.source} key={i} >
                    <FunctionField render={(e) => optionRenderer(e, v)} />
                  </ReferenceField>
                }
                else if (v.type === "reference_array_field") {
                  return <ReferenceArrayField {...v.props} source={v.source} key={i} link={false} >
                    <SingleFieldList linkType={false}>
                      <ChipField {...v.propsChip} />
                    </SingleFieldList>
                  </ReferenceArrayField>
                }
                else if (v.type === "text") {
                  return <TextField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'typography') {
                  return <Typography variant="body1" gutterBottom {...v.props} key={i}>
                    <FunctionField render={(e) => optionRenderer(e, v)} />
                  </Typography>
                }
                else if (v.type === "number") {
                  return <NumberField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === "data_grid_chip_translatable") {
                  return <DataGridChipTranslatable {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'data_grid_icon_translatable') {
                  return <DataGridIconTranslatable {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'photo_field') {
                  return <PhotoField {...v.props} source={v.source} key={i} />
                }
                else if (v.type === 'reference_link') {
                  return <ReferenceField {...v.props} source={v.source} key={i}>
                    {(v.icon !== undefined) ? (createElement(MuiIcons[v.icon])) : (optionRenderer(record, v))}
                  </ReferenceField>
                }
                else {
                  return (v.hidden === true) ? (null) : (<TextField source={v.name} label={v.label} key={i} />)
                }
              })}
              {(v.editFields === undefined || v.editFields.length === 0) ? (null) : (
                <EditInDialogButton fullWidth maxWidth="lg" {...v.propsEditInDialogButton}>
                  <SimpleForm defaultValues={makeObj(v.editDefaultValues, record)} toolbar={(customEditToolbar === undefined) ? (undefined) : (<EditToolbar {...customEditToolbar} />)}>
                    <BuildFields fields={v.editFields} subsDepth={subsDepth + 1} log={false} useSourcePrefix={useSourcePrefix} />
                  </SimpleForm>
                </EditInDialogButton>
              )}
            </Datagrid>
          </List>
        )
      }
      else if (v.type === 'div_html_value') {
        return <div {...v.props} dangerouslySetInnerHTML={{ __html: _.get(record, v.source) }} key={i} />
      }
      else {
        return (v.hidden === true) ? (null) : (<TextField source={v.name} label={v.label} key={i} />)
      }

    })}
  </SimpleShowLayout>
};


const BulkActionButtons = (props) => {
  return <>
    {props.actions.map((v, i) => {
      const icon = (v.icon === undefined) ? (null) : (createElement(MuiIcons[v.icon]))
      if (v.type === "delete") {
        return <BulkDeleteButton {...v.props} icon={icon} key={i} />
      }
      else if (v.type === "export") {
        return <BulkExportButton {...v.props} icon={icon} key={i} />
      }
      else if (v.type === "update") {
        return <BulkUpdateButton {...v.props} icon={icon} key={i} />
      }
      else if (v.type === "serverExportCurrentFilterSortButton") {
        return <BulkExportButton color={"inherit"} {...v.props} icon={icon} exporter={(objects, dateProvider, proxy, resource) => {
          const selectedIds = objects.map(v2 => v2.id)
          const listReportId = (v.listReportTemplateId === undefined) ? ("20") : (v.listReportTemplateId)
          const outputFormat = (v.outputFormat === undefined) ? ("xlsx") : (v.outputFormat)
          const nurl = `/list_report/${listReportId}/?selectedIds=${selectedIds.join(",")}&format=${outputFormat}`
          window.location.assign(nurl)
        }} key={i} />
      }
      else if (v.type === "calculationSum") {
        const [sum, setSum] = useState(0);
        const { resource, selectedIds } = useListContext(props);

        let s = 0
        useDataProvider().getMany(resource, { ids: selectedIds }).then(({ data }) => {
          if (v.transformation.type == "division") {
            data.map((order) => {
              s += parseFloat(_.get(order, v.transformation.field))
            })
            setSum(s / 100);
          }
        })
          .catch(error => {
            console.error(error);
          });

        return (
          <div {...v.props} key={i}>{sum + " ₽"}</div>
        )
      }
      else if (v.type === "updateForm") {
        return <BulkUpdateFormButton {...v.props} icon={icon} key={i} >
          <SimpleForm {...v.formProps}>
            <BuildFields fields={v.fields} log={false} useSourcePrefix={false} />
          </SimpleForm>
        </BulkUpdateFormButton>
      }
    })
    }
  </>
}


export const AdminEntityModelList = (props, entity) => {
  const dataProvider = useDataProvider();
  const list = JSON.parse(entity.list);
  const fields = (list.fields === undefined) ? ([{ "name": "id", "label": "#" }]) : (list.fields)
  const actionButtons = (list.actions === undefined) ? ([]) : (list.actions)
  const bulkActions = (list.bulkActions === undefined) ? ([]) : (list.bulkActions)
  const filters = (list.filters === undefined) ? ([]) : (list.filters)
  const list_sort = (list.sort === undefined) ? (null) : (list.sort)
  const listProps = (list.listProps === undefined) ? ({}) : (list.listProps);
  const expandFields = (list.expandFields === undefined) ? (undefined) : (list.expandFields);
  document.title = (list.tabTitle === undefined) ? ("Список " + entity.name) : (list.tabTitle)
  let listParams = useListParams(props);
  const [clickedRecord, setClickedRecord] = useState(null);

  const topCards = (list.topCards === undefined) ? ([]) : (list.topCards)
  const [topCardsVar1, setTopCardsVar1] = useState(null);
  const [topCardsVar2, setTopCardsVar2] = useState(null);
  const [topCardsVar3, setTopCardsVar3] = useState(null);
  const [topCardsVar4, setTopCardsVar4] = useState(null);
  const [topCardsVar5, setTopCardsVar5] = useState(null);
  const prepareTopCardsData = (vcard, index) => {
    if (vcard.type === "reference_list") {
      dataProvider.getList(vcard.resource, vcard.params).then(result => {
        if (index === 0) setTopCardsVar1(result.data); else
          if (index === 1) setTopCardsVar2(result.data); else
            if (index === 2) setTopCardsVar3(result.data); else
              if (index === 3) setTopCardsVar4(result.data); else
                if (index === 4) setTopCardsVar5(result.data);
      }).catch(error => { console.log(" -=-=-=-=-=- ", vcard.resource, "ERROR:", error) })
    }
  }
  const PrepareTopCardsView = (vtopcardprops) => {
    const { vcard, index } = vtopcardprops;
    const vcarddata = (index === 0) ? topCardsVar1 : (
      (index === 1) ? topCardsVar2 : (
        (index === 2) ? topCardsVar3 : (
          (index === 3) ? topCardsVar4 : (
            (index === 4) ? topCardsVar5 : undefined
          )
        )
      )
    );
    if (vcarddata === undefined) {
      return null;
    } else if (vcarddata === null) {
      return (<Card sx={{ textAlign: "center", padding: 2 }} {...vcard.cardProps} >
        <Loading loadingPrimary={null} loadingSecondary={null} {...vcard.loadingProps} />
      </Card>)
    } else if (vcard.type === "reference_list") {
      return vcarddata.map((v, i) =>
      (<Card sx={{ textAlign: "center", padding: 2 }} {...vcard.cardProps} key={`topVcardData${i}`}>
        {(vcard.icon !== undefined && vcard.icon !== null) ? createElement(MuiIcons[vcard.icon], { style: { height: 12 }, ...vcard.iconProps }) : null}
        {optionRenderer(v, vcard)}
      </Card>)
      )
    } else {
      return null
    }
  }
  if (topCards.length >= 1) prepareTopCardsData(topCards[0], 0);
  if (topCards.length >= 2) prepareTopCardsData(topCards[1], 1);
  if (topCards.length >= 3) prepareTopCardsData(topCards[2], 2);
  if (topCards.length >= 4) prepareTopCardsData(topCards[3], 3);
  if (topCards.length >= 5) prepareTopCardsData(topCards[4], 4);

  return (<div>
    {topCards.length === 0 ? null : <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(200px, 100%), 1fr))',
        gap: 2,
        margin: 2
      }}>
      {topCards.length >= 1 ? <PrepareTopCardsView vcard={topCards[0]} index={0} /> : null}
      {topCards.length >= 2 ? <PrepareTopCardsView vcard={topCards[1]} index={1} /> : null}
      {topCards.length >= 3 ? <PrepareTopCardsView vcard={topCards[2]} index={2} /> : null}
      {topCards.length >= 4 ? <PrepareTopCardsView vcard={topCards[3]} index={3} /> : null}
      {topCards.length >= 5 ? <PrepareTopCardsView vcard={topCards[4]} index={4} /> : null}
    </Box>}
    <List {...props} {...list.props}
      exporter={(list.reportTemplateId === undefined) ? ((objects, dateProvider, proxy, resource) => { console.log('ERROR: list.reportTemplateId is now defined!') }) : ((objects, dateProvider, proxy, resource) => {
        const selectedIds = objects.map(v2 => v2.id)
        const listReportId = (list.reportTemplateId === undefined) ? ("20") : (list.reportTemplateId)
        window.location.assign("/list_report/" + listReportId + "/?selectedIds=" + selectedIds.join(",") + "&resource=" + resource)
      })}
      filterDefaultValues={(list.type === 'calendar') ? (customGetFilterValues()) : (list.filterDefaultValues)}
      actions={<TopToolbar>
        {actionButtons.map((v, i) => {
          if (v.type === "create") {
            return (<CreateButton key={i} label={(v.label === undefined) ? (null) : (v.label)} />)
          }
          else if (v.type === "selectColumn") {
            return (<SelectColumnsButton key={i} label={(v.label === undefined) ? (null) : (v.label)} />)
          }
          else if (v.type === "filter") {
            return (<FilterButton key={i} label={(v.label === undefined) ? (null) : (v.label)} />)
          }
          else if (v.type === "export") {
            return (<ExportButton key={i} label={(v.label === undefined) ? (null) : (v.label)} />)
          }
          else if (v.type === "createInDialog") {
            return (<CreateInDialogButton fullWidth maxWidth="xl" key={i} label={(v.label === undefined) ? (null) : (v.label)} record={makeObj(v.createDefaultValues, {})}>
              {v.fields ? (
                <SimpleForm {...v.formProps}>
                  <BuildFields props={v} fields={v.fields} />
                </SimpleForm>
              ) : (<AdminEntityModelCreate {...props} entity={entity} />)}
            </CreateInDialogButton>)
          }
        })}
      </TopToolbar>}
      filters={
        filters.map((v, i) => {
          if (v.type === "text") {
            return (<TextInput {...v.props} source={v.source} key={i} />)
          }
          else if (v.type === "reference_select") {
            return (<ReferenceInput {...v.props} source={v.source} key={i}>
              <SelectInput {...v.propsSelectInput} />
            </ReferenceInput>)
          }
          else if (v.type === "referecne_autocomplete") {
            return (<ReferenceInput {...v.props} source={v.source} key={i}>
              <AutocompleteInput
                {...v.propsAutocompleteInput}
                optionText={(e) => optionRenderer(e, v)}
                filterToQuery={(text) => filterToQuery(text, v)}
              />
            </ReferenceInput>)
          }
          else if (v.type === "referecne_select_many") {
            return (<ReferenceArrayInput {...v.props} source={v.source} key={i}>
              <SelectArrayInput {...v.propsInput} source={v.source} optionText={(e) => optionRenderer(e, v)} key={i} />
            </ReferenceArrayInput>)
          }
          else if (v.type === "referecne_autocomplete_many") {
            let linked = (v.props === undefined || v.props.filter === undefined) ? ({}) : (v.props.filter);
            if (v.linked !== undefined) {
              let linkedValue = _.get(listParams[0].filterValues, v.linked.source)
              if (linkedValue !== null && linkedValue !== undefined) {
                linked[v.linked.field] = (v.linked.in === true) ? ([linkedValue]) : (linkedValue)
              }
            }
            return (<ReferenceArrayInput {...v.props} source={v.source} filter={linked} key={i}>
              <AutocompleteArrayInput {...v.propsAutocompleteInput} optionText={(e) => optionRenderer(e, v)} key={i} />
            </ReferenceArrayInput>)
          }
          else if (v.type === "select") {
            return (<SelectInput {...v.props} source={v.source} key={i} />)
          }
          else if (v.type === "date") {
            return (<DateInput {...v.props} source={v.source} key={i} />)
          }
          else if (v.type === "select_list_sub_reference") {
            return (<SelectListSubRef {...v.props} source={v.source} key={i} />)
          }
          else {
            return null
          }
        })}
      aside={(list.openedInSideBar !== undefined && clickedRecord !== null) ? (<OpenSidebar onClose={() => { setClickedRecord(null); }} record={clickedRecord} list={list} {...list.openedInSideBar.mainProps} />) : ((list.filterSidebar === undefined) ? (null) : (<FilterSidebar {...list.filterSidebar} />))}
      sort={list_sort}
      title={(list.title === undefined) ? ('Список: ' + entity.name) : (list.title)}
      pagination={<Pagination rowsPerPageOptions={(list.rowsPerPageOptions === undefined) ? ([10, 25, 50, 100]) : (list.rowsPerPageOptions)} {...props} />}
      perPage={(list.perPage === undefined) ? (50) : (list.perPage)}>

      <WithListContext render={({ data, isLoading, sort, filterValues, selectedIds }) => {
        return <div style={{ width: '100%', textAlign: 'right', }}>
          {actionButtons.map((v, i) => {
            if (v.type === "serverExportCurrentFilterSortButton") {
              return (<Button {...v.props} onClick={() => {
                console.log('sort', sort)
                console.log('filterValues', filterValues)
                console.log('selectedIds', selectedIds)
                const listReportId = (v.listReportTemplateId === undefined) ? ("20") : (v.listReportTemplateId)
                const outputFormat = (v.outputFormat === undefined) ? ("xlsx") : (v.outputFormat)
                const nurl = `/list_report/${listReportId}/?sort=${JSON.stringify(sort)}&filterValues=${JSON.stringify(filterValues)}&format=${outputFormat}`
                window.location.assign(nurl)
              }} label={v.label} key={i}>
                {createElement(MuiIcons[v.icon])}
              </Button>)
            }
          })}
        </div>
      }} />

      {(list.type === 'list') ? (
        <DatagridConfigurable
          rowClick={(list.openedInSideBar !== undefined) ? ((id, resource, record) => { setClickedRecord(record); return false; }) : ((list.rowClick === undefined) ? ('edit') : (list.rowClick))}
          bulkActionButtons={<BulkActionButtons actions={bulkActions} />}
          {...listProps}
          isRowExpandable={(list.isRowExpandable === undefined) ? (null) : ((record) => {
            if (list.isRowExpandable.const !== undefined) {
              return list.isRowExpandable.const
            } else {
              return _.get(record, list.isRowExpandable.field, list.isRowExpandable.defaultValue) === list.isRowExpandable.valueCondition
            }
          })}
          isRowSelectable={(list.isRowSelectable === undefined) ? (null) : ((record) => {
            if (list.isRowSelectable.const !== undefined) {
              return list.isRowSelectable.const
            } else {
              return _.get(record, list.isRowSelectable.field, list.isRowSelectable.defaultValue) === list.isRowSelectable.valueCondition
            }
          })}
          expand={(expandFields === undefined) ? (null) : (<ExpandRender fields={expandFields} />)} rowSx={record => {
            if (list.rowListStyle === undefined) {
              return null
            } else {
              if (list.rowListStyle.check == "transaction") {
                if (record[list.rowListStyle.field] < list.rowListStyle.value) {
                  return { backgroundColor: list.rowListStyle.backgroundColor }
                } else {
                  return null
                }
              }
              return { backgroundColor: list.rowListStyle.backgrounds[_.get(record, list.rowListStyle.field)] }
            }
          }}>
          {fields.map((v, i) => {
            if (v.type === "date") {
              return <DateField {...v.props} source={v.source} key={i} />
            }
            else if (v.type === "boolean") {
              return <BooleanField {...v.props} source={v.source} key={i} />
            }
            else if (v.type === "reference_field") {
              return <ReferenceField {...v.props} source={v.source} key={i} >
                <FunctionField render={(e) => optionRenderer(e, v)} />
              </ReferenceField>
            }
            else if (v.type === "reference_array_field") {
              return <ReferenceArrayField {...v.props} source={v.source} key={i} link={false} >
                <SingleFieldList linkType={false}>
                  <ChipField {...v.propsChip} />
                </SingleFieldList>
              </ReferenceArrayField>
            }
            else if (v.type === "data_grid_icon_translatable") {
              return <DataGridIconTranslatable {...v.props} source={v.source} key={i} />
            }
            else if (v.type === "text") {
              return <TextField {...v.props} source={v.source} key={i} />
            }
            else if (v.type === "number") {
              return <NumberField {...v.props} source={v.source} key={i} />
            }
            else if (v.type === "data_grid_chip_translatable") {
              return <DataGridChipTranslatable {...v.props} source={v.source} key={i} />
            }
            else if (v.type === 'photo_field') {
              return <PhotoField {...v.props} source={v.source} key={i} />
            }
            else if (v.type === 'rich_text_field') {
              return <RichTextField {...v.props} source={v.source} key={i} />
            }
            else if (v.type === 'typography') {
              return <FunctionField {...v.props} source={v.source} render={(e) => {
                return optionRenderer(e, v)
              }} key={i} />
            }
            else {
              return (v.hidden === true) ? (null) : (<TextField source={v.name} label={v.label} key={i} />)
            }

          })}
        </DatagridConfigurable>
      ) : (null)}
      {(list.type === 'calendar') ? (
        <Calendar {...listProps}
          convertToEvent={converter}
          getFilterValueFromInterval={customGetFilterValues} />
      ) : (null)}
    </List>
  </div>);
}


const ButtonServerExportReport = (props) => {
  const record = useRecordContext();
  if (record !== undefined) {
    const start_url = "/order_report/" + record.id + "/";
    return (
      <MButton {...props.linkProps} startIcon={createElement(MuiIcons[props.icon])} href={start_url + props.linkRecordProp}>
        {props.label}
      </MButton>
    );
  } else {
    return null
  }
}


const ARecordButton = (props) => {
  const record = useRecordContext();
  return (
    <MButton {...props.linkProps} startIcon={createElement(MuiIcons[props.icon])} href={_.get(record, props.linkRecordProp)}>
      {props.label}
    </MButton>
  );
};


const DataGridIconTranslatable = (props) => {
  const record = useRecordContext();
  const val = _.get(record, props.source)
  const translateDict = props.translateDict
  const translateColor = props.translateColor
  if (parseFloat(val)) {
    return val
  } else {
    let icon = (val !== null) ? (_.has(translateDict, val + "") ? translateDict[val + ""] : val + "") : (translateDict.undefined)
    let colorIcon = (val !== null) ? (_.has(translateColor, val + "") ? translateColor[val + ""] : val + "") : (translateColor.undefined)
    return createElement(MuiIcons[icon], { style: { color: colorIcon } })
  }
};


const DataGridChipTranslatable = (props) => {
  const record = useRecordContext();
  const val = _.get(record, props.source)
  const translateDict = props.translateDict
  if (val) {
    return (
      <Stack {...props.propsStack}>
        <Chip
          {...props.propsChip}
          label={(_.has(translateDict, val)) ? (translateDict[val].name ? translateDict[val].name : translateDict[val]) : (val)}
          style={{ backgroundColor: (_.has(translateDict, val)) ? (translateDict[val].color) : ("rgba(255, 255, 255, 0.16)") }}
        />
      </Stack>
    )
  }
};

const PhotoField = (props) => {
  const record = useRecordContext();
  let val = ""
  if (props.source !== undefined && props.source !== null) {
    val = _.get(record, props.source)
  } else {
    val = optionRenderer(record, props)
  }
  if (props.urlPrefix !== undefined && props.urlPrefix !== null && val) {
    val = props.urlPrefix + val
  }
  const translateDict = props.translateDict
  if (props.media) {
    return (<Card sx={{ display: 'block' }}>
      <CardMedia
        sx={{ width: '30em', height: '20em' }}
        alt=""
        component="img"
        {...props.propsCardMedia}
        image={val}
      />
    </Card>);
  } else {
    return (
      <Avatar variant='rounded' {...props.propsPhoto} src={_.has(translateDict, val) ? translateDict[val] : val} >{(props.avatarLabel && _.has(record, props.avatarLabel)) ? _.get(record, props.avatarLabel) : ""}</Avatar>
    );
  }
};


const TestButton = (props) => {
  const record = useRecordContext();
  const handleClick = () => {
    console.log('-=-=-=-=-=-=-=- asdasdasd', props, record)
  };
  return (
    <Button onClick={handleClick}>
      {props.label}
    </Button>
  );
};


const EditToolbar = (props) => {
  const [update] = useUpdate();
  const redirect = useRedirect();
  const { getValues } = useFormContext();
  const refresh = useRefresh();
  const recordId = useGetRecordId();
  const notify = useNotify();
  const { resource, successMessage, errorMessage, saveButtonProps, deleteButtonProps, customSaveButtonProps, toolbarProps } = props;
  const handleClick = e => {
    e.preventDefault();
    const { id, ...data } = getValues();
    update(
      resource,
      { id: recordId, data: data },
      {
        onSuccess: () => {
          notify(successMessage, { type: 'success' });
          refresh()
        },
        onError: (e) => {
          notify(errorMessage, { type: 'error' });
          refresh();
        }
      }
    );
  };
  const redirectClick = e => {
    e.preventDefault();
    const { id, ...data } = getValues();
    update(
      resource,
      { id: recordId, data: data },
      {
        onSuccess: () => {
          notify(successMessage, { type: 'success' });
          redirect(customSaveButtonProps?.redirect)
        },
        onError: (e) => {
          notify(errorMessage, { type: 'error' });
          refresh();
        }
      }
    );
  };

  return (
    <Toolbar {...toolbarProps}>
      <SaveButton type="button" onClick={handleClick} {...saveButtonProps} />
      {(customSaveButtonProps) ? (<UpdateButton type="button" onClick={(customSaveButtonProps?.redirect) ? (redirectClick) : (undefined())} {...customSaveButtonProps} />) : (null)}
      {(deleteButtonProps) ? (<DeleteButton type="button" {...deleteButtonProps} />) : (null)}
    </Toolbar>
  );
};


export const AdminEntityModelEdit = (props, entity) => {
  const form = useFormContext();
  const [simpleIteratorFilters, setSimpleIteratorFilters] = useState({});
  const edit = JSON.parse(entity.edit);
  const fields = (edit.fields === undefined) ? ([]) : (edit.fields);
  const sections = (edit.sections === undefined) ? ([]) : (edit.sections);
  const mutationMode = (edit.mutationMode === undefined) ? ("optimistic") : (edit.mutationMode);
  const redirect = (edit.redirect === undefined) ? ('list') : (edit.redirect);
  const actions = (edit.actions === undefined) ? ([]) : (edit.actions);
  const validation_schema = (edit.validation_schema === undefined) ? (undefined) : (edit.validation_schema);
  const formProps = (edit.formProps === undefined) ? ({}) : (edit.formProps);
  const customEditToolbar = (edit.customEditToolbar === undefined) ? (undefined) : (edit.customEditToolbar);
  return (<Edit {...props} actions={
    <TopToolbar>
      {actions.map((v, i) => {
        if (v.type === "clone") {
          return (<CloneButton {...v.props} key={i} />)
        }
        else if (v.type === "test_button") {
          return (<TestButton {...v.props} key={i} />)
        }
        else if (v.type === "update_button") {
          return (<UpdateButton {...v.props} key={i} />)
        }
        else if (v.type === "delete_with_confirmation_button") {
          return (<DeleteWithConfirmButton {...v.props} key={i} />)
        }
        else if (v.type === "delete_button") {
          return (<DeleteButton {...v.props} key={i} />)
        }
        else if (v.type === "ButtonServerExportReport") {
          return (<ButtonServerExportReport {...v.props} key={i} />)
        }
        else if (v.type === "a_button") {
          return (<ARecordButton {...v.props} key={i} />)
        } else
          return null;
      })}
    </TopToolbar>}
    title={<EditTitleRenderer v={edit} />}
    mutationMode={mutationMode} redirect={redirect}>
    {(edit.type === 'simple') ? (
      <SimpleForm {...formProps}
        toolbar={(customEditToolbar === undefined) ? (undefined) : (<EditToolbar {...customEditToolbar} />)}
        resolver={(edit.validation_schema === undefined) ? (undefined) : (ajvResolver(edit.validation_schema))}>
        <BuildFields {...props} fields={fields} edit={edit} simpleIteratorFilters={simpleIteratorFilters} setSimpleIteratorFilters={setSimpleIteratorFilters} />
      </SimpleForm>
    ) : (null)}
    {(edit.type === 'wizard') ? (
      <WizardForm {...formProps}
        toolbar={(customEditToolbar === undefined) ? (undefined) : (<EditToolbar {...customEditToolbar} />)}
        resolver={(edit.validation_schema === undefined) ? (undefined) : (ajvResolver(edit.validation_schema))}>
        {filterByShowCondition(sections, undefined, form).map((v, i) => {
          return (<WizardForm.Step label={v.label} key={i}>
            <BuildFields {...props} fields={v.fields} edit={edit} simpleIteratorFilters={simpleIteratorFilters} setSimpleIteratorFilters={setSimpleIteratorFilters} />
          </WizardForm.Step>);
        })}
      </WizardForm>
    ) : (null)}
    {(edit.type === 'tabbed') ? (
      <TabbedForm {...formProps} warnWhenUnsavedChanges={(customEditToolbar === undefined) ? (true) : ((customEditToolbar?.customSaveButtonProps?.redirect) ? (false) : (true))}
        toolbar={(customEditToolbar === undefined) ? (undefined) : (<EditToolbar {...customEditToolbar} />)}
        resolver={(edit.validation_schema === undefined) ? (undefined) : (ajvResolver(edit.validation_schema))}>
        {filterByShowCondition(sections, undefined, form).map((v, i) => {
          return (<TabbedForm.Tab label={v.label} key={i}>
            <BuildFields {...props} fields={v.fields} edit={edit} simpleIteratorFilters={simpleIteratorFilters} setSimpleIteratorFilters={setSimpleIteratorFilters} />
          </TabbedForm.Tab>);
        })}
      </TabbedForm>
    ) : (null)}
    {(edit.type === 'long') ? (
      <LongForm {...formProps}
        toolbar={(customEditToolbar === undefined) ? (undefined) : (<EditToolbar {...customEditToolbar} />)}
        resolver={(edit.validation_schema === undefined) ? (undefined) : (ajvResolver(edit.validation_schema))}>
        {filterByShowCondition(sections, undefined, form).map((v, i) => {
          return (<LongForm.Section label={v.label} key={i}>
            <BuildFields {...props} fields={v.fields} edit={edit} simpleIteratorFilters={simpleIteratorFilters} setSimpleIteratorFilters={setSimpleIteratorFilters} />
          </LongForm.Section>);
        })}
      </LongForm>
    ) : (null)}
    {(edit.type === 'accordion') ? (
      <AccordionForm {...formProps}
        toolbar={(customEditToolbar === undefined) ? (undefined) : (<EditToolbar {...customEditToolbar} />)}
        resolver={(edit.validation_schema === undefined) ? (undefined) : (ajvResolver(edit.validation_schema))}>
        {filterByShowCondition(sections, undefined, form).map((v, i) => {
          return (<AccordionForm.Panel label={v.label} key={i}>
            <BuildFields {...props} fields={v.fields} edit={edit} simpleIteratorFilters={simpleIteratorFilters} setSimpleIteratorFilters={setSimpleIteratorFilters} />
          </AccordionForm.Panel>);
        })}
      </AccordionForm>
    ) : (null)}
  </Edit>
  );
}


export const AdminEntityModelCreate = (props, entity) => {
  const ent = entity === undefined ? props.entity : entity
  // const entity = entity === undefined ? props.entity : entity
  // if (typeof value !== 'object') {
  //     entity = props.entity;
  // }
  const form = useFormContext()
  const create = JSON.parse(ent.create);
  const fields = (create.fields === undefined) ? ([]) : (create.fields);
  const sections = filterByShowCondition((create.sections === undefined) ? ([]) : (create.sections), undefined, form);
  const redirect = (create.redirect === undefined) ? ('list') : (create.redirect);
  const validation_schema = (create.validation_schema === undefined) ? (undefined) : (create.validation_schema);
  const formProps = (create.formProps === undefined) ? ({}) : (create.formProps);
  const createProps = (create.createProps === undefined) ? ({}) : (create.createProps);
  document.title = (create.tabTitle === undefined) ? ("Создание " + ent.name) : (create.tabTitle)
  return (<Create {...props} {...createProps} redirect={redirect}>
    {(create.type === 'simple') ? (
      <SimpleForm {...formProps} resolver={(create.validation_schema === undefined) ? (undefined) : (ajvResolver(create.validation_schema))}>
        <BuildFields {...props} fields={fields} />
      </SimpleForm>
    ) : (null)}
    {(create.type === 'wizard') ? (
      <WizardForm {...formProps} resolver={(create.validation_schema === undefined) ? (undefined) : (ajvResolver(create.validation_schema))}>
        {sections.map((v, i) => {
          return (<WizardForm.Step label={v.label} key={i}>
            <BuildFields {...props} fields={v.fields} />
          </WizardForm.Step>);
        })}
      </WizardForm>
    ) : (null)}
    {(create.type === 'tabbed') ? (
      <TabbedForm {...formProps} resolver={(create.validation_schema === undefined) ? (undefined) : (ajvResolver(create.validation_schema))}>
        {sections.map((v, i) => {
          return (<TabbedForm.Tab label={v.label} key={i}>
            <BuildFields {...props} fields={v.fields} />
          </TabbedForm.Tab>);
        })}
      </TabbedForm>
    ) : (null)}
    {(create.type === 'long') ? (
      <LongForm {...formProps} resolver={(create.validation_schema === undefined) ? (undefined) : (ajvResolver(create.validation_schema))}>
        {sections.map((v, i) => {
          return (<LongForm.Section label={v.label} key={i}>
            <BuildFields {...props} fields={v.fields} />
          </LongForm.Section>);
        })}
      </LongForm>
    ) : (null)}
    {(create.type === 'accordion') ? (
      <AccordionForm {...formProps} resolver={(create.validation_schema === undefined) ? (undefined) : (ajvResolver(create.validation_schema))}>
        {sections.map((v, i) => {
          return (<AccordionForm.Panel label={v.label} key={i}>
            <BuildFields {...props} fields={v.fields} />
          </AccordionForm.Panel>);
        })}
      </AccordionForm>
    ) : (null)}
  </Create>
  );
}
