
import gql from 'graphql-tag'

const buildDashboardDataQuery = (raFetchType, resourceName, params) => {
    return {
        query: gql('query {'+
            'maxSumDaysTradeResults(daysCount:10) { purchases,sum,date }'+
            'maxSumMonthsTradeResults(monthsCount:6) { purchases,sum,date }'+
            'maxPurchasesDaysTradeResults(daysCount:10) { purchases,sum,date }'+
            'maxPurchasesMonthsTradeResults(monthsCount:6) { purchases,sum,date }'+
            'lastDaysTradeResults(daysCount:10) { purchases,sum,date }'+
            'lastMonthsTradeResults(monthsCount:6) { purchases,sum,date }'+
          '}'),
        // variables: {
        //     data: JSON.stringify(params.data),
        // },
        parseResponse: response => {
            return {
                data: response,
            }
        },
    }

}

export default buildDashboardDataQuery;
