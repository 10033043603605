import React from 'react';
import { useEffect } from 'react';
import {NumberInput, useInput} from 'react-admin';

const KitAppNumber = (props) => {
  const { field } = useInput(props);

  useEffect(() => {
    if (props.transformation.type === "division") {
      props.form.setValue("fake_" + props.source, Math.round(field.value/props.transformation.value * 100) / 100);
    }
  }, [field]);

  return (
    <NumberInput {...props} source={"fake_"+props.source} 
      onChange={(e) => {
        if (props.transformation.type === "division") {
          return props.form.setValue(props.source, Math.round(e.target.value*props.transformation.value * 100) / 100);
        }
      }} 
    />
  );
}

export default KitAppNumber;
