
import gql from 'graphql-tag'

const buildImportProductsIcmlQuery = (raFetchType, resourceName, params) => {
    return {
        query: gql('mutation mImportProductsIcmlMutation($icml: String, $loadPic: Boolean) { importProductsIcmlMutation(icml: $icml, loadPic: $loadPic) { result, categoryCreated,categoryUpdated, productCreated, productUpdated, photoLoaded }}'),
        variables: {
            icml: params.icml_data,
            loadPic: params.load_photo,
        },
        parseResponse: response => {
            return {
                data: response,
            }
        },
    }

}

export default buildImportProductsIcmlQuery;
