
import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
    Title,
    useDataProvider,
} from 'react-admin';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import SendIcon from '@material-ui/icons/Send';

class ManagerPay extends React.Component {

    dataProvider = null;
    state = {
        sum: 500,
        loading: false,
        result: null,
        error: false,
    }
    constructor(props) {
        super(props);
        this.dataProvider = props.dataProvider;
    }

    cancel = (e) => {
        console.log(this.props);
    }

    sum_changed = (e) => {
        this.setState({
            sum: e.target.value
        })
    }
    send = (e) => {
        this.setState({loading: true, error: false});
        this.dataProvider.target('ManagerPay', {sum: this.state.sum})
            .then((result)=> {
                let error = result.data.data.managerPayMutation.error;
                let url = result.data.data.managerPayMutation.url;
                if (error === '' && url !== '') {
                    this.setState({loading: false, error: false});
                    window.location.replace(url);
                } else {
                    this.setState({loading: false, error: true});
                }
            })
            .catch((error)=>{
                this.setState({loading: false, error: true});
            });
    }

    render() {
        return (<div>
            <Title title="Оплата" />
            <CardContent>
            Оплатить сервис:<br /><br />
            <Box
                component="form"
                noValidate
                autoComplete="off" >
                <TextField
                    disabled={this.state.loading}
                    error={this.state.error}
                    label="Сумма для оплаты"
                    id="filled-multiline-static"
                    variant="filled"
                    defaultValue={"500"}
                    onChange={(e)=>{this.sum_changed(e);}}
                />
                <img src="https://workshop.kit-app.ru/assets/images/payment-systems.png" alt="payment systems" style={{margin:16}} /><br/>
                <Button variant="contained" endIcon={<SendIcon />} style={{margin:16, width:256}} onClick={(e)=>{this.send(e);}}>
                    Перейти к оплате
                </Button>
                {this.state.error ? (<span style={{color:'#f00'}}>Ошибка, попробуйте позднее</span>) : (null)}
            </Box>
            
            </CardContent>
        </div>);
    }

}

function ShowManagerPay() {
    return (<ManagerPay dataProvider={useDataProvider()} />);
}

export default ShowManagerPay;
