
import gql from 'graphql-tag'

const buildManagerPayQuery = (raFetchType, resourceName, params) => {
    return {
        query: gql('mutation { managerPayMutation(sum:'+params.sum+') { url, error } }'),
        parseResponse: response => {
            return {
                data: response,
            }
        },
    }

}

export default buildManagerPayQuery;
