
import gql from 'graphql-tag'

const fields = 'id, name, order, icon, contentType{appLabel, model}, enabled, list, show, create, edit'
const buildAdminEntityQuery = (raFetchType, resourceName, params) => {

    if (raFetchType === 'GET_LIST') {
        var q = '';
        if (params.filter.q !== undefined) {
            q = params.filter.q;
        }
        var publish = '';
        if (params.filter.publish !== undefined) {
            publish = params.filter.publish;
        }
        return {
            query: gql('query {' +
                'adminEntitys(page:' + params.pagination.page + ',perPage:' + params.pagination.perPage + ',sortField:"' + params.sort.field + '",sortOrder:"' + params.sort.order + '", q:"' + q + '", publish:"' + publish + '") {' +
                fields +
                '}' +
                'adminEntityCount' +

                ' }'),
            parseResponse: response => {
                console.log("response.data")
                console.log(response.data)
                console.log("response.data")
                return {
                    data: response.data.adminEntitys,
                    total: response.data.adminEntityCount
                }
            },
        }
    }
    if (raFetchType === 'GET_MANY') {
        return {
            query: gql('query {' +
                'adminEntitys {' +
                fields +
                '}' +
                '}'),
            parseResponse: response => {
                return {
                    data: response.data.adminEntitys,
                }
            },
        }
    }
    if (raFetchType === 'GET_ONE') {
        if (params.id === 'create') {
            return {}
        }
        return {
            query: gql('query {' +
                'adminEntity(id:' + params.id + ') {' +
                fields +
                '}' +
                '}'),
            parseResponse: response => {
                return {
                    data: {
                        id: response.data.adminEntity.id,
                        data: response.data,
                    },
                }
            },
        }
    }
    if (raFetchType === 'CREATE') {
        return {
            query: gql(
                'mutation {' +
                'createAdminEntity(contentType:' + params.data.contentType.model + ',icon:"' + params.data.icon + '",name:"' + params.data.name + '",) {' +
                'adminEntity {' +
                fields +
                '} } }'
            ),
            parseResponse: response => {
                return {
                    data: response.data.createAdminEntity.adminEntity,
                }
            },
        }
    }
    if (raFetchType === 'UPDATE') {
        return {
            query: gql(
                'mutation mUpdateProduct($files: String, $filesToDelete: String, $categories: String, $tags: String, $prices: String, $discounts: String) {' +
                'updateProduct(' +
                'id:' + params.data.data.product.id + ',' +
                'filesToDelete: $filesToDelete, ' +
                'files: $files, ' +
                'prices: $prices, ' +
                'discounts: $discounts, ' +
                'categories: $categories, ' +
                'tags: $tags, ' +
                'slug:"' + params.data.data.product.slug + '",' +
                'description:"' + params.data.data.product.description.replaceAll('"', '\\"') + '",' +
                'name:"' + params.data.data.product.name.replaceAll('"', '\\"') + '",' +
                'externalId:"' + params.data.data.product.externalId + '",' +
                'autoAddOrder:' + params.data.data.product.autoAddOrder + ',' +
                'publish:' + params.data.data.product.publish + ',) {' +
                'product {' + fields + '} } }'
            ),
            variables: {
                files: filesToUpload,
                filesToDelete: JSON.stringify(fileToDelete),
                tags: JSON.stringify(params.data.data.productInTags),
                categories: JSON.stringify(params.data.data.productInCategories),
                prices: JSON.stringify(params.data.data.productPrices),
                discounts: JSON.stringify(params.data.data.productDiscounts),
            },
            parseResponse: response => {
                localStorage.setItem('filesToUpload', '[]');
                return {
                    data: response.data.updateProduct.product,
                }
            },
        }
    }
    if (raFetchType === 'DELETE') {
        return {
            query: gql(
                'mutation {' +
                'deleteProduct(id:' + params.id + ') {' +
                'result ' +
                '} }'
            ),
            parseResponse: response => {
                return {
                    data: response.data.deleteProduct.result,
                }
            },
        }
    }
    if (raFetchType === 'DELETE_MANY') {
        return {
            query: gql(
                'mutation { deleteManyProduct(ids:[' + params.ids + ']) { result } }'
            ),
            parseResponse: response => {
                return {
                    data: params.ids,
                }
            },
        }
    }
}

export default buildAdminEntityQuery;
