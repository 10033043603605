
import { Layout } from 'react-admin';
import { StandAppBar } from './AppBar';
import { StandMenu } from './Menu';

export const StandLayout = (props, adminLayout) => {
    return (<Layout
        {...props}
        // menu={(e) => { return new StandMenu(e, adminLayout); }}
        // menu={null}
        appBar={StandAppBar}
    />);
}
