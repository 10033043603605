
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import gql from 'graphql-tag';

const gclient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: '/gql/',
    headers: {
      authorization: localStorage.getItem('user'),
    }
  }),
  connectToDevTools: true,
});

const StandClient = {
  getAdminConfigure: () => new Promise((resolve, reject) => {
    let query = gql`query{ adminLayouts { id, name, code, root, entities{ id, name, code, entity, list, show, edit, create, icon, backSettings, parent{ id, name }, contentType{ model } } } }`;
    gclient.query({ query: query, fetchPolicy: "no-cache" }).then(result => {
      resolve({ result });
    }).catch(error => {
      reject({ error })
    });
  }),
  //   getManagerBalance: () => new Promise((resolve, reject) => {
  //     let query = gql`query{ getManagerBalance{balance} }`;
  //     gclient.query({ query: query, fetchPolicy: "no-cache" }).then(result => {
  //       resolve({ result });
  //     }).catch(error => {
  //       reject({ error })
  //     });
  //   }),
}


export default StandClient;

