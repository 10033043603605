import gql from 'graphql-tag'
import buildAdminLayoutCustomQuery from './AdminLayoutCustomQuery'
import buildImportProductsIcmlQuery from '../models/custom/import_products_icml/query';
import buildManagerPayQuery from '../models/custom/manager_pay/query'
import buildDashboardDataQuery from '../models/custom/dashboard/query'
import buildAdminEntityCustomQuery from './generated/AdminEntity'
import buildContentTypesQuery from './generated/ContentType'

const buildQuery = introspectionResults => (raFetchType, resourceName, params) => {

    // console.log('-=-=-=-=-=-')
    // console.log('raFetchType=', raFetchType)
    // console.log('resourceName=', resourceName)
    // console.log('params=', params)
    // console.log('-=-=-=-=-=-')

    if (resourceName === 'ImportProductsIcml') {
        return buildImportProductsIcmlQuery(raFetchType, resourceName, params)
    }

    if (resourceName === 'ManagerPay') {
        return buildManagerPayQuery(raFetchType, resourceName, params)
    }

    if (resourceName === 'GetDashboardData') {
        return buildDashboardDataQuery(raFetchType, resourceName, params)
    }

    if (resourceName === 'GetEmployeeShifts') {
        return buildManagerPayQuery(raFetchType, resourceName, params)
    }

    if (resourceName === 'adminentity') {
        return buildAdminEntityCustomQuery(raFetchType, resourceName, params)
    }

    if (resourceName === 'ContentType') {
        return buildContentTypesQuery(raFetchType, resourceName, params)
    }

    let customQuery = buildAdminLayoutCustomQuery(raFetchType, resourceName, params);
    if (customQuery !== null) {
        return customQuery;
    }

    console.log(' -------------------------------- raFetchType=' + raFetchType + ' ----------------------------');
    console.log(' -------------------------------- resourceName=' + resourceName + ' ----------------------------');
    console.log(' -------------------------------- params: ----------------------------');
    console.log(params);

    return {
        query: gql`query {
            allConfigs {
                id
                name
                value
            }
            Allconfigsmeta {
                count
            }
        }`,
        variables: params,
        parseResponse: response => {
            console.log(' ++++++++++++++++++++++++++ reponse: ++++++++++++++++++++++++++');
            console.log(response);
            return {
                data: response.data.allConfigs,
                total: response.data.Allconfigsmeta.count
            }
        },
    }
}

export default buildQuery;
