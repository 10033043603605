
import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

const gclient = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
        uri: '/gql/',
        headers: {
            authorization: localStorage.getItem('user'),
        }
    }),
    connectToDevTools: true,
})

const authProvider = {
    login: ({ username, password }) => {
        // console.log('authProvider ----- login');
        localStorage.setItem('filesToDelete', '[]');
        localStorage.setItem('filesToUpload', '[]');
        const query = gql('query {' +
            'authManager(login:"' + username + '", password: "' + password + '") {' +
            'user {id, email, username}' +
            'token' +
            '} }');
        return gclient.query({
            query: query
        }).then(result => {
            localStorage.setItem('user', JSON.stringify(result.data.authManager));
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }).catch(() => {
            throw new Error('Сервер недоступен')
        });
    },
    checkError: (error) => {
        console.log('authProvider ----- checkError', error);
        return Promise.resolve();
    },
    checkAuth: () => {
        // console.log('authProvider ----- checkAuth');
        localStorage.setItem('filesToDelete', '[]');
        localStorage.setItem('filesToUpload', '[]');
        const a = localStorage.getItem('user');
        if (!a) {
            return Promise.reject()
        }
        if (a === 'null') {
            localStorage.removeItem('user');
            return Promise.reject({ message: 'Не подходит логин или пароль!' });
        }
        const q = JSON.parse(a);
        const query = gql('query {' +
            'checkAuthManager(token:"' + q.token + '") {' +
            'user {id, email, username}' +
            'token' +
            '} }');
        return gclient.query({
            query: query
        })
            .then(result => {
                localStorage.setItem('user', JSON.stringify(result.data.checkAuthManager));
                return Promise.resolve();
            }).catch(() => {
                throw new Error('Сервер недоступен')
            });
    },
    getIdentity: () => {
        console.log('authProvider ----- getIdentity');
        try {
            const userData = JSON.parse(localStorage.getItem('user'));
            const id = userData.user.id;
            const fullName = userData.user.username;
            return Promise.resolve({ id, fullName, });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: (params) => {
        // console.log('authProvider ----- getPermissions');
        const userData = JSON.parse(localStorage.getItem('user'));
        if (userData) {
            const id = userData.user.id;
            const fullName = userData.user.username;
            return Promise.resolve({ id, fullName, });
        } else {
            window.location.href = "/admin/login";
        }
    },
    logout: () => {
        // console.log('authProvider ----- logout');
        localStorage.removeItem('user');
        localStorage.setItem('filesToDelete', '[]');
        localStorage.setItem('filesToUpload', '[]');
        return Promise.resolve();
    },
};

export default authProvider;
