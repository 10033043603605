
import { Component } from 'react';
import { AppBar, Link, ToggleThemeButton, defaultTheme, InspectorButton } from 'react-admin';
import { Box, Typography } from '@mui/material';

import BalanceIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import StandClient from '../stand-data-provider/StandClient';

export class StandAppBarBalance extends Component {
  constructor(props) {
    super();
    this.state = {
      balance: localStorage.getItem('managerBalance'),
    }
  }

  //   componentDidMount() {
  //     if (localStorage.getItem('user') !== null && localStorage.getItem('user') !== undefined) {
  //       StandClient.getManagerBalance().then(e => {
  //         localStorage.setItem('managerBalance', e.result.data.getManagerBalance.balance);
  //         this.setState({
  //           balance: e.result.data.getManagerBalance.balance
  //         });
  //       });
  //     }
  //   }

  render() {
    if (this.state.balance !== null) {
      return (<Link to="/manager_pay" style={{ color: '#fff', margin: 8, fontSize: 15 }}>
        Баланс: {(this.state.balance / 100).toFixed(2)}₽
      </Link>);
    }
    return (<span>...</span>);
  }
}

const darkTheme = {
  palette: { mode: 'dark' },
};

export const StandAppBar = props => {
  return (<AppBar {...props}>
    <Box flex="1">
      <Typography variant="h6" id="react-admin-title"></Typography>
    </Box>
    <ToggleThemeButton lightTheme={defaultTheme} darkTheme={darkTheme} />
    <BalanceIcon />
    {/* <StandAppBarBalance /> */}
    <InspectorButton />
  </AppBar>);
}


