
import gql from 'graphql-tag'

const fields = 'id, appLabel, model'
const buildContentTypesQuery = (raFetchType, resourceName, params) => {

    if (raFetchType === 'GET_LIST') {
        var q = '';
        if (params.filter.q !== undefined) {
            q = params.filter.q;
        }
        var publish = '';
        if (params.filter.publish !== undefined) {
            publish = params.filter.publish;
        }
        return {
            query: gql('query {' +
                'contentTypes(page:' + params.pagination.page + ',perPage:' + params.pagination.perPage + ',sortField:"' + params.sort.field + '",sortOrder:"' + params.sort.order + '", q:"' + q + '", publish:"' + publish + '") {' +
                fields +
                '}' +
                'contentTypeCount' +

                ' }'),
            parseResponse: response => {
                return {
                    data: response.data.contentTypes,
                    total: response.data.contentTypesCount
                }
            },
        }
    }
    if (raFetchType === 'GET_MANY') {
        return {
            query: gql('query {' +
                'contentTypes {' +
                fields +
                '}' +
                '}'),
            parseResponse: response => {
                return {
                    data: response.data.contentTypes,
                }
            },
        }
    }
    if (raFetchType === 'GET_ONE') {
        if (params.id === 'create') {
            return {}
        }
        return {
            query: gql('query {' +
                'contentType(id:' + params.id + ') {' +
                fields +
                '}' +
                '}'),
            parseResponse: response => {
                return {
                    data: {
                        id: response.data.contentType.id,
                        data: response.data,
                    },
                }
            },
        }
    }
    // if (raFetchType === 'CREATE') {
    //     return {
    //         query: gql(
    //             'mutation {' +
    //             'createAdminEntity(description:"' + desc + '",name:"' + params.data.name.replaceAll('"', '\\"') + '",) {' +
    //             '} }'
    //         ),
    //         parseResponse: response => {
    //             console.log(response.data.createAdminEntity)
    //             console.log("response.data.createAdminEntity")
    //             return {
    //                 data: response.data.createAdminEntity,
    //             }
    //         },
    //     }
    // }
    // if (raFetchType === 'UPDATE') {
    //     return {
    //         query: gql(
    //             'mutation mUpdateProduct($files: String, $filesToDelete: String, $categories: String, $tags: String, $prices: String, $discounts: String) {' +
    //             'updateProduct(' +
    //             'id:' + params.data.data.product.id + ',' +
    //             'filesToDelete: $filesToDelete, ' +
    //             'files: $files, ' +
    //             'prices: $prices, ' +
    //             'discounts: $discounts, ' +
    //             'categories: $categories, ' +
    //             'tags: $tags, ' +
    //             'slug:"' + params.data.data.product.slug + '",' +
    //             'description:"' + params.data.data.product.description.replaceAll('"', '\\"') + '",' +
    //             'name:"' + params.data.data.product.name.replaceAll('"', '\\"') + '",' +
    //             'externalId:"' + params.data.data.product.externalId + '",' +
    //             'autoAddOrder:' + params.data.data.product.autoAddOrder + ',' +
    //             'publish:' + params.data.data.product.publish + ',) {' +
    //             'product {' + fields + '} } }'
    //         ),
    //         variables: {
    //             files: filesToUpload,
    //             filesToDelete: JSON.stringify(fileToDelete),
    //             tags: JSON.stringify(params.data.data.productInTags),
    //             categories: JSON.stringify(params.data.data.productInCategories),
    //             prices: JSON.stringify(params.data.data.productPrices),
    //             discounts: JSON.stringify(params.data.data.productDiscounts),
    //         },
    //         parseResponse: response => {
    //             localStorage.setItem('filesToUpload', '[]');
    //             return {
    //                 data: response.data.updateProduct.product,
    //             }
    //         },
    //     }
    // }
    // if (raFetchType === 'DELETE') {
    //     return {
    //         query: gql(
    //             'mutation {' +
    //             'deleteProduct(id:' + params.id + ') {' +
    //             'result ' +
    //             '} }'
    //         ),
    //         parseResponse: response => {
    //             return {
    //                 data: response.data.deleteProduct.result,
    //             }
    //         },
    //     }
    // }
    // if (raFetchType === 'DELETE_MANY') {
    //     return {
    //         query: gql(
    //             'mutation { deleteManyProduct(ids:[' + params.ids + ']) { result } }'
    //         ),
    //         parseResponse: response => {
    //             return {
    //                 data: params.ids,
    //             }
    //         },
    //     }
    // }
}

export default buildContentTypesQuery;
