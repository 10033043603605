import {
  SimpleForm,
  useCreateSuggestionContext,
  useCreate,
  Toolbar,
  SaveButton,
} from 'react-admin';
import {Dialog, DialogContent} from '@mui/material';
import {BuildFields} from '../../../models/AdminEntityModel'

let isString = value => typeof value === 'string' || value instanceof String;

const CreateReference = (props) => {
  const {onCancel} = useCreateSuggestionContext();
  const [create] = useCreate();

  const handleSubmit = event => {
    if (event.__spot_update) {
      delete event.__spot_update
    }
    create(
      props.props.reference,
      {data: event},
      {
        onSuccess: (data) => {
          let newItem = {id: data.id}
          if (_.get(props, "optionList") === undefined) {
            if (_.has(event, "name")) {
              newItem["name"] = event.name
            }
          } else {
            _.get(props, "optionList").forEach(e => {
              if (isString(e)) {
                if (_.has(event, e)) {
                  newItem[e] = _.get(event, e)
                }
              } else {
                newItem[e.field] = _.get(event, e.field)
                if (_.has(e, "transformation")) {
                  if (e.transformation.type === "division") {
                    newItem[e.field] = newItem[e.field] / e.transformation.value
                  }
                }
              }
            })
          }
          props.getNewValue(newItem);
          onCancel();
        },
      }
    );
  };

  return (
    <Dialog {...props.create.props} open onClose={onCancel}>
      <SimpleForm onSubmit={handleSubmit} toolbar={<Toolbar><SaveButton label="Сохранить"/></Toolbar>}>
        <DialogContent>
          <BuildFields props={props} fields={props.create.createFields} />
        </DialogContent>
      </SimpleForm>
    </Dialog>
  );
}

export default CreateReference;
