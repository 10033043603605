import { DateInput, DateTimeInput } from "ra-ui-materialui";
import { useWatch } from "react-hook-form";

const KitAppMinMaxDateOrTimeInput = (props) => {
    const v = props.v;
    let prs = (v.inputProps === undefined) ? ({}) : (v.inputProps);
    const disabled = (props.disabled === undefined) ? (false) : (props.disabled);

    if (v.minValue !== undefined) {
        try {
            if (v.minValue.linked === undefined) {
                var minDate = new Date();
            } else {
                var minDate = new Date(useWatch({ name: ((v.minValue.linked.useSourcePrefix) ? (props.source + '.' + v.minValue.linked.source) : (v.minValue.linked.source)) }));
            }
            if (v.minValue.days) { minDate.setDate(minDate.getDate() + v.minValue.days); }
            if (v.minValue.months) { minDate.setMonth(minDate.getMonth() + v.minValue.months); }
            if (v.minValue.years) { minDate.setFullYear(minDate.getFullYear() + v.minValue.years); }
            prs.min = minDate.toISOString().slice(0, 10);
        } catch (error) {
            console.log('MIN VALUE ERROR', v, error)
        }
    }

    if (v.maxValue !== undefined) {
        try {
            if (v.maxValue.linked === undefined) {
                var maxDate = new Date();
            } else {
                var maxDate = new Date(useWatch({ name: ((v.maxValue.linked.useSourcePrefix) ? (props.source + '.' + v.maxValue.linked.source) : (v.maxValue.linked.source)) }));
            }
            if (v.maxValue.days) { maxDate.setDate(maxDate.getDate() + v.maxValue.days); }
            if (v.maxValue.months) { maxDate.setMonth(maxDate.getMonth() + v.maxValue.months); }
            if (v.maxValue.years) { maxDate.setFullYear(maxDate.getFullYear() + v.maxValue.years); }
            prs.max = maxDate.toISOString().slice(0, 10);
        } catch (error) {
            console.log('MAX VALUE ERROR', v, error)
        }
    }

    if (props.type == "DateInput") {
        return <DateInput disabled={disabled} {...v.props} inputProps={prs} source={props.nsource} />;
    }
    if (props.type == "DateTimeInput") {
        return <DateTimeInput disabled={disabled} {...v.props} inputProps={prs} source={props.nsource} />;
    }
}

export default KitAppMinMaxDateOrTimeInput;
