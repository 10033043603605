
import React, { useEffect, useState } from "react";
import { Route } from 'react-router-dom';
import { QueryClient } from 'react-query';
import { Admin, Resource, CustomRoutes, defaultTheme, Layout } from 'react-admin'
import buildGraphQLProvider from 'ra-data-graphql';
import { ThreeCircles } from 'react-loader-spinner'
import authProvider from './stand-auth-provider/index'
import { AdminEntityModelList, AdminEntityModelCreate, AdminEntityModelEdit } from "./models/AdminEntityModel";
import buildQuery from './stand-data-provider/index'
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import { StandLayout } from "./layout/Layout";
import { Dashboard } from "./models/custom/dashboard";
import StandClient from "./stand-data-provider/StandClient";
import ShowImportProductsIcml from "./models/custom/import_products_icml";
import ShowManagerPay from "./models/custom/manager_pay";
import ShowEmployeesShifts from "./models/custom/employees_shifts";
import { ContainerLayout, SolarLayout } from '@react-admin/ra-navigation';
import * as MuiIcons from "@material-ui/icons";

russianMessages.ra['configurable'] = {};
russianMessages.ra.configurable.customize = 'Customize';
russianMessages.ra.action.clear_array_input = 'Очистить список';
russianMessages.ra.message.clear_array_input = 'Вы уверены что хотите очистить список?';
russianMessages.ra.saved_queries = {
    label: 'Запросы',
    query_name: 'Имя запроса',
    new_label: 'Сохранить текущий запрос...',
    new_dialog_title: 'Сохранить текущий запрос как',
    remove_label: 'Удалить сохраненный запрос',
    remove_label_with_name: 'Удалить запрос "%{name}"',
    remove_dialog_title: 'Удалить сохраненный запрос?',
    remove_message: 'Вы уверены, что хотите удалить этот элемент из списка сохраненных запросов?',
    help: 'Отфильтруйте список и сохраните этот запрос на будущее.',
};
russianMessages['ra-form-layout.action.previous'] = 'Назад';
russianMessages['ra-form-layout.action.next'] = 'Далее';
russianMessages['ra.action.select_columns'] = 'Колонки';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const App = () => {

    const [state, setState] = useState({
        layoutLoading: false,
        dataProvider: null,
        adminLayout: null,
    });

    useEffect(() => {
        const user = localStorage.getItem('user');
        buildGraphQLProvider({
            buildQuery,
            client: new ApolloClient({
                cache: new InMemoryCache(),
                link: new HttpLink({
                    uri: '/gql/',
                    headers: {
                        authorization: user,
                    }
                }),
                connectToDevTools: true,
            }),
        }).then(dataProvider => {
            setState(prev => {
                return {
                    ...prev,
                    dataProvider: dataProvider
                }
            });
        });
        if (user !== null && user !== undefined) {
            setState(prev => {
                return {
                    ...prev,
                    layoutLoading: true
                }
            });
            StandClient.getAdminConfigure().then(e => {
                localStorage.setItem('adminLayout', JSON.stringify(e.result.data.adminLayouts));
                setState(prev => {
                    return {
                        ...prev,
                        layoutLoading: false,
                        adminLayout: e.result.data.adminLayouts,
                    }
                });
            }).catch(error => {
                localStorage.removeItem('managerBalance');
                localStorage.removeItem('adminLayout');
                localStorage.removeItem('user');
                setState(prev => {
                    return {
                        ...prev,
                        layoutLoading: false,
                        adminLayout: null,
                    }
                });
            });
        }
    }, []);
    const { dataProvider } = state;
    if (!dataProvider || state.layoutLoading) {
        return (
            <div style={{
                display: 'flex', alignItems: 'center',
                justifyContent: 'center', marginTop: 12
            }}>
                <ThreeCircles width={200} height={200} color="#2196f3" radius={5} />
            </div>
        );
    }
    if (state.adminLayout === null || state.adminLayout === undefined) {
        return (
            <Admin requireAuth theme={defaultTheme} dataProvider={dataProvider} authProvider={authProvider} i18nProvider={i18nProvider} />
        );
    }
    let root = JSON.parse(state.adminLayout[0].root)
    let ll = (e) => { return StandLayout(e, state.adminLayout) };
    if (root.layoutType === "Layout") {
        ll = Layout;
    } else if (root.layoutType === "ContainerLayout") {
        ll = ContainerLayout;
    } else if (root.layoutType === "SolarLayout") {
        ll = SolarLayout;
    }
    let showDashboardButton = (root.showDashboardButton === undefined) ? (false) : (root.showDashboardButton);
    const darkTheme = { ...defaultTheme, palette: { mode: 'dark' } };

    const ManagerDashboard = (props) => {
        if (showDashboardButton) {
            return (<Dashboard dataProvider={dataProvider} backSettings={root} {...props} />);
        } else {
            return null;
        }
    }

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                staleTime: 'Infinity'
            },
        },
    });
    return (
        <Admin queryClient={queryClient} requireAuth theme={defaultTheme} darkTheme={darkTheme} defaultTheme="light" dashboard={(showDashboardButton) ? (ManagerDashboard) : (null)} layout={ll} dataProvider={dataProvider} authProvider={authProvider} i18nProvider={i18nProvider} >
            <CustomRoutes>
                <Route path="/import_products_icml" Component={ShowImportProductsIcml} />
                <Route path="/manager_pay" Component={ShowManagerPay} />
                <Route path="/employees_shifts" Component={ShowEmployeesShifts} />
            </CustomRoutes>
            {state.adminLayout.map((vv, ii) => {
                return vv.entities.map((v, i) => {
                    //редирект на админские сущности. Работает только для админа.
                    const backSettings = JSON.parse(v.backSettings);
                    if (window.location.pathname.includes(backSettings.redirect)) {
                        let newPath = vv.code + "/" + v.code;
                        window.location.pathname.split("/").splice(4).map(e => { newPath += "/" + e })
                        window.location.href = "/admin/" + newPath;
                    }
                    return (
                        <Resource icon={(MuiIcons[v.icon])} options={{ label: v.name }} name={vv.code + "/" + v.code} list={(e) => { return AdminEntityModelList(e, v); }} edit={(e) => { return AdminEntityModelEdit(e, v) }} create={(e) => { return AdminEntityModelCreate(e, v) }} key={ii + "_" + i} />
                    );
                })
            })}
        </Admin>
    );
}

export default App;
