
import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
    Title,
    useDataProvider,
    Link,
} from 'react-admin';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from '@mui/material/AlertTitle';
import LinearProgress from '@mui/material/LinearProgress';

import SendIcon from '@material-ui/icons/Send';

class ImportProductsIcml extends React.Component {

    dataProvider = null;
    state = {
        val: '',
        load_photo: false,
        loading: false,
        result: null,
        error: false,
    }
    constructor(props) {
        super(props);
        this.dataProvider = props.dataProvider;
    }


    cancel = (e) => {
        console.log(this.props);
    }
    
    val_changed = (e) => {
        this.setState({
            val: e.target.value
        })
    }
    
    load_photo_changed = (e) => {
        this.setState({
            load_photo: e.target.checked
        });
    }
        
    send = (e) => {
        if (this.state.val === '') {
            return 
        }
        this.setState({loading: true, error: false});
        this.dataProvider.target('ImportProductsIcml', {icml_data: this.state.val, load_photo: this.state.load_photo})
            .then((result)=> {
                this.setState({loading: false});
                if (result.data.data.importProductsIcmlMutation == null) {
                    this.setState({loading: false, error: true});
                } else {
                    this.setState({loading: false, error: false, result: result.data.data.importProductsIcmlMutation});
                }
            });
    }

    render() {
        return (<div>
            <Title title="Импорт товаров из ICML-файла" />
            <CardContent>
            Импорт товаров из ICML-файла:<br /><br />
            <Box
                component="form"
                noValidate
                autoComplete="off" >
                <TextField
                    disabled={this.state.loading}
                    error={this.state.error}
                    label="Вставьте содержимое файла ICML/XML в поле"
                    multiline
                    minRows={12}
                    fullWidth={true}
                    id="filled-multiline-static"
                    variant="filled"
                    onChange={(e)=>{this.val_changed(e);}}
                />
                <br /><br />
                { this.state.loading ? (
                    <Alert style={{textAlign:"left"}} severity="info">
                        <LinearProgress color="success" />
                        Пожалуйста подождите идет обработка...
                    </Alert>
                ) : (
                    <div fullWidth={true} style={{textAlign:"right"}} >
                        {this.state.result != null && this.state.result.result ? (
                            <Alert style={{textAlign:"left"}} onClose={() => {this.setState({result:null})}} severity="info">
                                <AlertTitle>Успешно</AlertTitle>
                                Количество добавленных категорий: { this.state.result.categoryCreated } <br />
                                Количество обновленных категорий: { this.state.result.categoryUpdated } <br />
                                Количество добавленных товаров: { this.state.result.productCreated } <br />
                                Количество обновленных товаров: { this.state.result.productUpdated } <br />
                                Количество загруженных фотографий: { this.state.result.photoLoaded } <br />
                                <Link to={"/Product"} style={{margin:16}}>К списку товаров</Link>
                            </Alert>
                        ) : null}
                        {this.state.error ? (
                            <Alert style={{textAlign:"left"}} onClose={() => {this.setState({error:false})}} severity="error">
                                <AlertTitle>Ошибка</AlertTitle>
                                Неверный формат
                            </Alert>
                        ):null}
                        <Checkbox onChange={(e)=>{this.load_photo_changed(e);}}/> Загрузить все фотографии
                        <Link to={"/Product"} style={{margin:16, width:256}}>Отмена</Link> {" "} 
                        <Button variant="contained" endIcon={<SendIcon />} style={{margin:16, width:256}} onClick={(e)=>{this.send(e);}}>
                            Отправить
                        </Button>
                    </div>
                )}
            </Box>
            
            </CardContent>
        </div>);
    }

}

function ShowImportProductsIcml() {
    return (<ImportProductsIcml dataProvider={useDataProvider()} />);
}

export default ShowImportProductsIcml;
