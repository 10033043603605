import { createElement } from 'react';

import { ResponsiveAreaBump, ResponsiveBump } from '@nivo/bump';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveBoxPlot } from '@nivo/boxplot';
import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import { ResponsiveBullet } from '@nivo/bullet';
import { ResponsiveCalendar, ResponsiveTimeRange } from '@nivo/calendar';
import { ResponsiveChoropleth, ResponsiveGeoMap } from '@nivo/geo';
import { ResponsiveChord } from '@nivo/chord';
import { ResponsiveFunnel } from '@nivo/funnel';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { ResponsiveLine, LineCanvas, Line } from '@nivo/line';
import { ResponsiveMarimekko } from '@nivo/marimekko';
import { ResponsiveNetwork } from '@nivo/network';
import { ResponsiveParallelCoordinates } from '@nivo/parallel-coordinates';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { ResponsiveSankey } from '@nivo/sankey';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { ResponsiveStream } from '@nivo/stream';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot';
import { ResponsiveTree } from '@nivo/tree';
import { ResponsiveTreeMap } from '@nivo/treemap';
import { ResponsiveVoronoi } from '@nivo/voronoi';
import { ResponsiveWaffle } from '@nivo/waffle';

const defaultScheme = "nivo";
const defaultSchemes = { "light": "paired", "dark": "dark2" };
const defaultTheme = {};
const defaultThemesColors = {
  "light": {
    "scheme": "paired",
    "text": {
      "fill": "#000000"
    },
    "grid": {
      "line": {
        "stroke": "#ddd"
      }
    },
    "tooltip": {
      "container": {
        "background": "#fff"
      }
    }
  },
  "dark": {
    "scheme": "dark2",
    "text": {
      "fill": "#ffffff"
    },
    "grid": {
      "line": {
        "stroke": "#ddd"
      }
    },
    "tooltip": {
      "container": {
        "background": "#121212"
      }
    }
  }
};

const nivoComponents = {
  "areabump": ResponsiveAreaBump,
  "bump": ResponsiveBump,
  "bar": ResponsiveBar,
  "boxplot": ResponsiveBoxPlot,
  "circlepacking": ResponsiveCirclePacking,
  "bullet": ResponsiveBullet,
  "calendar": ResponsiveCalendar,
  "timerange": ResponsiveTimeRange,
  "choropleth": ResponsiveChoropleth,
  "geomap": ResponsiveGeoMap,
  "chord": ResponsiveChord,
  "funnel": ResponsiveFunnel,
  "heatmap": ResponsiveHeatMap,
  "line": Line,
  "marimekko": ResponsiveMarimekko,
  "network": ResponsiveNetwork,
  "parallelcoordinates": ResponsiveParallelCoordinates,
  "pie": ResponsivePie,
  "radar": ResponsiveRadar,
  "radialbar": ResponsiveRadialBar,
  "sankey": ResponsiveSankey,
  "scatterplot": ResponsiveScatterPlot,
  "stream": ResponsiveStream,
  "sunburst": ResponsiveSunburst,
  "swarmplot": ResponsiveSwarmPlot,
  "tree": ResponsiveTree,
  "treemap": ResponsiveTreeMap,
  "voronoi": ResponsiveVoronoi,
  "waffle": ResponsiveWaffle,
};

const defaultGraphsMargin = 35;
const defaultPropsSimpleResponsiveLine = {
  height: 500,
  width: 500,
  margin: { top: defaultGraphsMargin, right: defaultGraphsMargin, bottom: defaultGraphsMargin, left: defaultGraphsMargin },
};

export const NivoSimpleGraph = ({ data, v, currentTheme }) => {
  // console.log('-==-=-=-=-=-=-=-=-=-=- SimpleResponsiveLine data=', data, ' theme=', currentTheme);
  const gClassName = v.graphComponent || "line";
  const gClass = (nivoComponents[gClassName] !== undefined) ? (nivoComponents[gClassName]) : (Line);
  const element = data ? createElement(gClass, {
    style: {
      width: v.width || 500,
      height: v.height || 500,
      display: v.display || "block"
    },
    width: v.width || 500,
    height: v.height || 500,
    enableTouchCrosshair: true,
    useMesh: true,
    theme: ((v.themesColors !== undefined && v.themesColors[currentTheme] !== undefined) ? v.themesColors[currentTheme] : (defaultThemesColors[currentTheme] || defaultTheme)),
    colors: { scheme: ((v.themesColors !== undefined && v.themesColors[currentTheme] !== undefined && v.themesColors[currentTheme]["scheme"] !== undefined) ? v.themesColors[currentTheme]["scheme"] : (defaultSchemes[currentTheme] || defaultScheme)) },
    ...defaultPropsSimpleResponsiveLine,
    ...v.props,
    data: data,
  }) : null;
  return element;
};