import gql from 'graphql-tag'

const buildAdminLayoutCustomQuery = (raFetchType, resourceName, params) => {

    // console.log('---=--=-=-=-=-')
    // console.log('---=--=-=-=-=- raFetchType=', raFetchType)
    // console.log('---=--=-=-=-=- resourceName=', resourceName)
    // console.log('---=--=-=-=-=- params=', params)
    // console.log('---=--=-=-=-=-')

    const parse_uploaded_files = (p, k, depth) => {
        Object.keys(p).forEach(v => {
            if (_.isObject(_.get(params.data, k + v))) {
                if (_.has(params.data, k + v + '__upload_results')) {
                    const ll = _.get(params.data, k + v + '__upload_results').filter(m => _.has(m, 'data')).map(m => { return { id: m.data.id, src: m.data.url, title: m.data.url, uploaded: true } })
                    let arr = _.get(params.data, k + v).filter(f => !_.has(f, 'rawFile'))
                    ll.forEach(a => {
                        if (arr.findIndex(v => v.id === a.id) === -1) {
                            arr.push(a)
                        }
                    })
                    _.set(params.data, k + v, arr)
                } else {
                    parse_uploaded_files(_.get(params.data, k + v), k + v + '.', depth + 1)
                }
            }
        })
    }

    let adminLayout = localStorage.getItem('adminLayout', null)
    if (adminLayout === null) {
        return null;
    }
    adminLayout = JSON.parse(adminLayout)[0];
    if (adminLayout.entities.length === 0) {
        return null
    }
    const entity = adminLayout.entities[0];

    if ((resourceName === 'UploadPhotoFile') && (raFetchType === 'CREATE')) {
        return {
            query: gql(
                'mutation {' +
                'uploadStaticFileWithAdminLayout(fileName:"' + params.fileName + '",fileData:"' + params.fileData + '",resourceName:"photo") {' +
                'id,url ' +
                '} }'
            ),
            parseResponse: response => {
                return {
                    data: response.data.uploadStaticFileWithAdminLayout,
                }
            },
        }
    }
    if ((resourceName === 'UploadStaticFile') && (raFetchType === 'CREATE')) {
        return {
            query: gql(
                'mutation {' +
                'uploadStaticFileWithAdminLayout(fileName:"' + params.fileName + '",fileData:"' + params.fileData + '") {' +
                'id,url ' +
                '} }'
            ),
            parseResponse: response => {
                return {
                    data: response.data.uploadStaticFileWithAdminLayout,
                }
            },
        }
    }
    if (raFetchType === 'GET_LIST') {
        return {
            query: gql('query {' +
                'listingForEntity(adminEntity:' + entity.id +
                ',resourceName:"' + resourceName + '"' +
                ',sort:"' + JSON.stringify(params.sort).replaceAll("\"", "\\\"") + '"' +
                ',pagination:"' + JSON.stringify(params.pagination).replaceAll("\"", "\\\"") + '"' +
                ',filter:"' + JSON.stringify(params.filter).replaceAll("\"", "\\\"") + '"' +
                '){total, data}' +
                '}'),
            parseResponse: response => {
                return {
                    data: response.data.listingForEntity.data,
                    total: response.data.listingForEntity.total
                }
            }
        }
    }
    if (raFetchType === 'GET_MANY_REFERENCE') {
        let filter = params.filter
        filter[params.target + '__in'] = params.id.id
        return {
            query: gql('query {' +
                'listingForEntity(adminEntity:' + entity.id +
                ',resourceName:"' + resourceName + '"' +
                ',sort:"' + JSON.stringify(params.sort).replaceAll("\"", "\\\"") + '"' +
                ',pagination:"' + JSON.stringify(params.pagination).replaceAll("\"", "\\\"") + '"' +
                ',filter:"' + JSON.stringify(filter).replaceAll("\"", "\\\"") + '"' +
                '){total, data}' +
                '}'),
            parseResponse: response => {
                return {
                    data: response.data.listingForEntity.data,
                    total: response.data.listingForEntity.total
                }
            }
        }
    }
    if (raFetchType === 'GET_MANY') {
        if (Array.isArray(params.ids[0])) {
            params.ids = params.ids
        } else if (typeof params.ids[0] === 'object' && params.ids[0] !== null) {
            params.ids = [params.ids[0]['id']]
        }
        return {
            query: gql('query {' +
                'listingForEntity(adminEntity:' + entity.id +
                ',resourceName:"' + resourceName + '"' +
                ',filter:"' + JSON.stringify({ id__in: params.ids }).replaceAll("\"", "\\\"") + '"' +
                '){data}' +
                '}'),
            parseResponse: response => {
                return {
                    data: response.data.listingForEntity.data,
                }
            },
        }
    }
    if (raFetchType === 'GET_ONE') {
        if (params.id === 'create') {
            return {}
        }
        if (typeof params.id === 'object' && !Array.isArray(params.id) && params.id !== null) {
            params.id = params.id['id']
        }
        return {
            query: gql('query {' +
                'listingForEntity(adminEntity:' + entity.id +
                ',resourceName:"' + resourceName + '"' +
                ',filter:"' + JSON.stringify({ id: params.id }).replaceAll("\"", "\\\"") + '"' +
                '){data}' +
                '}'),
            parseResponse: response => {
                return {
                    data: response.data.listingForEntity.data[0]
                }
            },
        }
    }
    if (raFetchType === 'CREATE') {
        parse_uploaded_files(params.data, '', 0)
        return {
            query: gql(
                'mutation {' +
                'createEntityWithAdminLayout (' +
                ',resourceName:"' + resourceName + '"' +
                ',data:"' + JSON.stringify(params.data).replaceAll("\\", "\\\\").replaceAll("\"", "\\\"") + '"' +
                ') {created{newId}} }'
            ),
            parseResponse: response => {
                return {
                    data: { id: response.data.createEntityWithAdminLayout.created.newId }
                }
            },
        }
    }
    if (raFetchType === 'UPDATE') {
        parse_uploaded_files(params.data, '', 0)
        const objId = ((_.has(params.data, 'id')) ? (params.data.id) : (params.id))
        return {
            query: gql(
                'mutation {' +
                'updateEntityWithAdminLayout(' +
                'id:' + objId +
                ',resourceName:"' + resourceName + '"' +
                ',data:' + JSON.stringify(JSON.stringify(params.data)) +
                ') {updated{newId}} }'
            ),
            parseResponse: response => {
                return {
                    data: { id: response.data.updateEntityWithAdminLayout.updated.newId },
                }
            },
        }
    }
    if (raFetchType === 'UPDATE_MANY') {
        parse_uploaded_files(params.data, '', 0)
        const objIds = ((_.has(params.data, 'ids')) ? (params.data.ids) : (params.ids))
        return {
            query: gql(
                'mutation {' +
                'updateEntityWithAdminLayout(' +
                'ids:"' + objIds.toString() +
                '",resourceName:"' + resourceName + '"' +
                ',data:' + JSON.stringify(JSON.stringify([params.data])) +
                ') {updated{newId}} }'
            ),
            parseResponse: response => {
                return {
                    data: [],
                    // data: { id: response.data.updateEntityWithAdminLayout.updated },
                }
            },
        }
    }
    if (raFetchType === 'DELETE_MANY' || raFetchType === 'DELETE') {
        const ids = params.id ? [params.id] : params.ids;
        return {
            query: gql(
                'mutation {' +
                'deleteManyEntityWithAdminLayout(ids:[' + ids + '], resourceName:"' + resourceName + '") {' +
                'result ' +
                '} }'
            ),
            parseResponse: response => {
                return {
                    data: ids,
                }
            },
        }
    }
    return null;
}

export default buildAdminLayoutCustomQuery;
