
import * as React from "react";
import CardContent from '@material-ui/core/CardContent';
import {
  Title,
  useDataProvider,
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  FunctionField,
} from 'react-admin';
import Box from "@mui/material/Box";
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Calendar from 'react-calendar';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Moment from 'moment';

// import 'react-calendar/dist/Calendar.css';
import './Calendar.css'

class ManagerEmployeesShifts extends React.Component {
  dataProvider = null;
  state = {
  }
  constructor(props) {
    super(props);
    this.dataProvider = props.dataProvider;
    this.state = {
      props: props,
      // sum: 500,
      loading: false,
      // result: null,
      // error: false,
      tabIndex: 0,
      shifts: null,
      salePoint: null,
      startDate: null,
    }
  }

  componentDidMount() {
    this.loadShifts();
  }

  loadShifts = () => {
    this.setState({shifts: null, loading:true});
    const params = {salePoint: this.state.salePoint,startCalendarDate: (this.state.startDate)?(Moment(this.state.startDate).format('DD.MM.YYYY')):(''), pagination:{page: 1, perPage: 500}, sort:{field: "id", order: "ASC"}};
    this.dataProvider.getList('EmployeeShift', params)
      .then((result)=> {
        this.setState({shifts: result.data, loading:false});
      })
      .catch((error)=>{
        console.log(error);
      });
  }

  handleTabChange = (e, v) => {
    this.setState({tabIndex: v});
  }

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  empAddSelLabel = (record) => {
    return record.name+' '+record.lastName+' ('+record.balance+')';
  }

  onAddEmployeeToShift = (e, date) => {
    this.dataProvider.create('EmployeeShift', {fastAddWithCalendar:true,employeeId:e.target.value, date:Moment(date).format('DD.MM.YYYY'), salePoint: this.state.salePoint})
      .then((result)=> {
        this.loadShifts();
      })
      .catch((error)=>{
        console.log(error);
      });
  }

  salePointSelLabel = (record) => {
    return record.name;
  }

  salePointCalendarChange = (rec) => {
    this.setState({salePoint: rec}, (e)=>{
      this.loadShifts();
    });
  }

  shifts_color = (v) => {
    if (v.canceled) {
      return "error";
    }
    if (!v.employee.enabled) {
      return "error";
    }
    if (v.finished) {
      if (v.transactionCreated) {
        return "success";
      } else {
        return "warning";
      }
    } else {
      return "info";
    }
  }

  shifts_tooltip = (v) => {
    if (v.canceled) {
      return (<div>СМЕНА ОТМЕНЕНА!<br />{v.comment}<br/>Стоимость смены: {v.employee.salary/100}₽ {v.employee.comment ? ('Заметка о сотруднике: '+v.employee.comment) : ('')}</div>);
    }
    if (v.finished) {
      if (v.transactionCreated) {
        return (<div>Смена завершена, з\п начислена.<br />{v.comment}<br/>Стоимость смены: {v.employee.salary/100}₽ {v.employee.comment ? ('Заметка о сотруднике: '+v.employee.comment) : ('')}</div>);
      } else {
        return (<div>Смена завершена, но з\п НЕ начислена.<br />{v.comment}<br/>Стоимость смены: {v.employee.salary/100}₽ {v.employee.comment ? ('Заметка о сотруднике: '+v.employee.comment) : ('')}</div>);
      }
    } else {
      if (!v.employee.enabled) {
        return (<div>ВЫБРАННЫЙ СОТРУДНИК ОТКЛЮЧЕН!<br />{v.comment}<br/>Стоимость смены: {v.employee.salary/100}₽ {v.employee.comment ? ('Заметка о сотруднике: '+v.employee.comment) : ('')}</div>);
      } else {
        return (<div>Запланированная смена.<br />{v.comment}<br/>Стоимость смены: {v.employee.salary/100}₽ {v.employee.comment ? ('Заметка о сотруднике: '+v.employee.comment) : ('')}</div>);
      }
    }
  }

  calendatTile = ({activeStartDate, date, view}) => {
    let inShift = [];
    if (this.state.shifts && view === 'month') {
      const d = Moment(date).format('YYYY-MM-DD');
      inShift = this.state.shifts.filter((v,i)=>{
        return v.date == d;
      });
    }
    return (<div style={{minHeight:100}}>
      {inShift.map((v)=>{
        return (<Tooltip title={this.shifts_tooltip(v)} key={v.id} arrow placement="right">
          <Chip style={{margin:1}} color={this.shifts_color(v)} label={v.employee.name+' '+v.employee.lastName}></Chip>
        </Tooltip>);
      })}
      {(this.state.salePoint && view === 'month')?(
      <SimpleForm toolbar={null}>
        <ReferenceInput {...this.state.props} source="+" label="Добавить" reference="Employee" >
          <SelectInput fullWidth optionText={this.empAddSelLabel} onChange={(e)=>{this.onAddEmployeeToShift(e, date)}} />
        </ReferenceInput>
      </SimpleForm>):(null)}
    </div>);
  }

  calendarNavigate = ({ action, activeStartDate, value, view }) => {
    if (view === 'month') {
      this.setState({startDate:activeStartDate}, ()=>{
        this.loadShifts();
        // Moment(activeStartDate).format('DD.MM.YYYY')
      });
    }
  }

  balanceLabel = (e) => {
    console.log(e);
    return <TextField label="Баланс" value='111' />
  }

  render() {
    return (<div>
      <Title title="Сотрудники" />
      <CardContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={this.state.tabIndex} onChange={this.handleTabChange} aria-label="basic tabs example">
            <Tab label="Сотрудники" {...this.a11yProps(0)} />
            <Tab label="Смены" {...this.a11yProps(1)} />
            {/* <Tab label="Item Three" {...this.a11yProps(2)} /> */}
          </Tabs>
        </Box>

        {this.state.tabIndex === 0 ? (
          <div>
            <List resource="Employee" title="Сотрудники" perPage={50}>
              <Datagrid rowClick="edit">
                {/* <TextField source="id" label="id" /> */}
                <TextField source="name" label="Имя" />
                <TextField source="lastName" label="Фамилия" />
                <FunctionField label="З/П" source="salary" render={record => (record.salary/100).toFixed(2) } />
                <FunctionField label="Баланс" source="balance" render={record => (record.balance/100).toFixed(2) } />
                <BooleanField source="enabled" label="Вкл" />
                {/* <TextField source="post.name" label="Должность" /> */}
              </Datagrid>
            </List>
          </div>
        ) : (null)}

        {this.state.tabIndex === 1 ? (
          <div value={this.state.tabIndex} index={0}>
            <SimpleForm toolbar={null}>
              <ReferenceInput {...this.state.props} source="Точка продаж" reference="SalePoint" >
                <SelectInput fullWidth optionText={this.salePointSelLabel} onChange={(e)=>{this.salePointCalendarChange(e.target.value)}} onEmptied={(e)=>{this.salePointCalendarChange(null)}} />
              </ReferenceInput>
            </SimpleForm>
            { (this.state.loading || this.state.shifts === null) ? (
              <div>Загрузка...</div>
            ) : (null) }
            <Calendar tileContent={this.calendatTile} onActiveStartDateChange={(e) => this.calendarNavigate(e)} />
          </div>
        ) : (null)}

        {this.state.tabIndex === 2 ? (
          <div value={this.state.tabIndex} index={0}>
            Item 3
          </div>
        ) : (null)}

      </CardContent>
    </div>);
  }

}

function ShowEmployeesShifts() {
  return (<ManagerEmployeesShifts dataProvider={useDataProvider()} />);
}

export default ShowEmployeesShifts;
