import { useState, useMemo } from 'react';
import { ListContextProvider } from 'react-admin';

const useLocalListController = (initialData, options = {}) => {
    // Состояния для управления списком
    const [data, setData] = useState(initialData);
    const [page, setPage] = useState(options.page || 1);
    const [perPage, setPerPage] = useState(options.perPage || 10);
    const [sort, setSort] = useState(options.sort || { field: 'id', order: 'ASC' });
    const [filter, setFilter] = useState(options.filter || {});
    const [selectedIds, setSelectedIds] = useState([]);

    // Фильтрация данных
    const filteredData = useMemo(() => {
    return data.filter(item => {
        return Object.entries(filter).every(([key, value]) => {
        if (value === undefined || value === null || value === '') return true;
        return String(item[key]).toLowerCase().includes(String(value).toLowerCase());
        });
    });
    }, [data, filter]);

    // Сортировка данных
    const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
        const aValue = a[sort.field];
        const bValue = b[sort.field];
        
        if (sort.order === 'ASC') {
        return aValue > bValue ? 1 : -1;
        } else {
        return aValue < bValue ? 1 : -1;
        }
    });
    }, [filteredData, sort]);

    // Пагинация данных
    const paginatedData = useMemo(() => {
    const start = (page - 1) * perPage;
    return sortedData.slice(start, start + perPage);
    }, [sortedData, page, perPage]);

    // Функции для работы с выделенными элементами
    const onSelect = (id) => setSelectedIds(prev => [...prev, id]);
    const onToggleItem = (id) => setSelectedIds(prev => 
    prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]
    );
    const onUnselectItems = () => setSelectedIds([]);

    return {
    // Данные
    data: paginatedData,
    total: filteredData.length,
    ids: paginatedData.map(item => item.id),
    baseData: data, // Исходные данные
    
    // Состояния
    page,
    perPage,
    sort,
    filterValues: filter,
    selectedIds,
    isFetching: false,
    isLoading: false,
    
    // Функции
    setData,
    setPage,
    setPerPage,
    setSort,
    setFilters: setFilter,
    onSelect,
    onToggleItem,
    onUnselectItems,
    
    // Для совместимости с react-admin компонентами
    resource: null,
    refetch: () => {},
    };
};

// Компонент-обертка для использования с react-admin
const LocalListController = ({ data, children }) => {
    const controllerProps = useLocalListController(data);
    
    return (
    <ListContextProvider value={controllerProps}>
        {children}
    </ListContextProvider>
    );
};

export default LocalListController;
