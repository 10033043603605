import { Component } from 'react';
import { Title, Link, useDataProvider, List, Datagrid, TextField } from 'react-admin';

// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
// import { Grid, Box, Paper } from "@material-ui/core";
// import  from '@material-ui/core/Paper';


import { styled } from '@mui/material/styles';
import { Typography, Grid, Box, Paper } from '@mui/material';
// import { StandResponsiveLine } from './lineGraph';
// import { makeStyles } from '@material-ui/core/styles';


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export const Dashboard = (props) => {
  // const classes = useStyles();
  const { dataProvider, backSettings } = props;
  const { dashboard } = backSettings;
  //   analyticsKeys = {
  //     "lastDaysTradeResults": "Последние дни",
  //     "lastMonthsTradeResults": "Последние месяцы",
  //     "maxPurchasesDaysTradeResults": "Дни максимальных продаж",
  //     "maxPurchasesMonthsTradeResults": "Месяцы максимальных продаж",
  //     "maxSumDaysTradeResults": "Дни наибольшей суммы продаж",
  //     "maxSumMonthsTradeResults": "Месяцы наибольшей суммы продаж",
  //   }
  // constructor(props) {
  //   super();
  //   this.dataProvider = props.dataProvider;
  //   this.dashboard = props.dashboard;
  //   this.state = {
  //     data: null,
  //   }
  // }
  //   componentDidMount() {
  //     this.dataProvider.target('GetDashboardData', {})
  //       .then((result)=> {
  //         console.log(result.data.data);
  //         this.setState({
  //           data: result.data.data,
  //         })
  //       });
  //   }
  //   drawGraph = (props) => {
  //     let arr = [];
  //     props.data.forEach((element)=>{
  //       let val = element.purchases;
  //       if (props.name === 'maxSumMonthsTradeResults' || props.name === 'maxSumDaysTradeResults') {
  //         val = element.sum;
  //       }
  //       arr.push({
  //         x: element.date,
  //         y: val,
  //       })
  //     });
  //     let d = [{
  //       id: this.analyticsKeys[props.name],
  //       data: arr,
  //     }];
  //     return (
  //       <StandResponsiveLine key={props.name} data={d} />
  //     );
  //   }
  // render() {
  //   console.log('-=-=-=-=-=-=- ManagerDashboard render=', this.dashboard)
  if (dashboard === undefined || dashboard === null) {
    return null;
  } else {
    return (
      <div>
        <Title title={(dashboard.title) ? (dashboard.title) : ("Главная")} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} marginTop={1}>
            {dashboard.boxes && dashboard.boxes.map((v, i) => {
              return (<Grid item xs={12} {...v.gridProps} key={`${i}-${v.type}`}>
                <Item>
                  {v.type === "text" && (<Typography {...v.typographyProps}>{v.text}</Typography>)}
                  {v.type === "list" && (<><Typography {...v.typographyProps}>{v.text}</Typography><List disableSyncWithLocation storeKey={false} {...v.listProps}>
                    <Datagrid {...v.dataGridProps}>
                      {v.fields.map((v2, i2) => {
                        return (<TextField {...v2.props} source={v2.source} key={`${i}-${v.type}#${i2}`} />)
                      })}
                    </Datagrid>
                  </List></>)}
                </Item>
              </Grid>);
            })}
          </Grid>
        </Box>
      </div>
    );
  }
  //     const xs = 6;
  //     return (
  //       <div>
  //         <Title title="Главная" />
  //         <Box sx={{ flexGrow: 1 }}>
  //           <Grid container  spacing={2}>
  //             <Grid item xs={12}>
  //               <Typography style={{margin:4}} >Быстрый доступ</Typography>
  //               <Link variant="contained" size="large" style={{margin:4}} to="/Product" >Товары</Link>
  //               <Link variant="contained" size="large" style={{margin:4}} to="/User" >Пользователи</Link>
  //               <Link variant="contained" size="large" style={{margin:4}} to="/Order" >Заказы</Link>
  //               <Link variant="contained" size="large" style={{margin:4}} to="/Integration" >Интеграции</Link>
  //             </Grid>
  //             <Grid item xs={12}>
  //               <Typography style={{margin:4}} >Сводка</Typography>
  //             </Grid>
  //             { this.state.data === null ? ("загрузка...") : (<Grid item xs={xs} style={{height:400}}>
  //                 <this.drawGraph name={"lastDaysTradeResults"} data={this.state.data.lastDaysTradeResults} />
  //               </Grid>)}
  //             { this.state.data === null ? ("загрузка...") : (<Grid item xs={xs} style={{height:400}}>
  //                 <this.drawGraph name={"lastMonthsTradeResults"} data={this.state.data.lastMonthsTradeResults} />
  //               </Grid>)}
  //             { this.state.data === null ? ("загрузка...") : (<Grid item xs={xs} style={{height:400}}>
  //                 <this.drawGraph name={"maxPurchasesDaysTradeResults"} data={this.state.data.maxPurchasesDaysTradeResults} />
  //               </Grid>)}
  //             { this.state.data === null ? ("загрузка...") : (<Grid item xs={xs} style={{height:400}}>
  //                 <this.drawGraph name={"maxPurchasesMonthsTradeResults"} data={this.state.data.maxPurchasesMonthsTradeResults} />
  //               </Grid>)}
  //             { this.state.data === null ? ("загрузка...") : (<Grid item xs={xs} style={{height:400}}>
  //                 <this.drawGraph name={"maxSumDaysTradeResults"} data={this.state.data.maxSumDaysTradeResults} />
  //               </Grid>)}
  //             { this.state.data === null ? ("загрузка...") : (<Grid item xs={xs} style={{height:400}}>
  //                 <this.drawGraph name={"maxSumMonthsTradeResults"} data={this.state.data.maxSumMonthsTradeResults} />
  //               </Grid>)}
  //           </Grid>
  //         </Box>
  //       </div>
  //     );
  // }
}

// export const ManagerDashboard = (props) => {
//   console.log('-=-=-=-=-=-=- ManagerDashboard=', props)
//   return (<Dashboard dataProvider={useDataProvider()} {...props} />);
//   // return (null);
// }
